<template>
  <ul class="pagination" :class="paginationClass">
    <li class="page-item prev-page" :class="{disabled: value === 1}">
      <a class="page-link" aria-label="Previous" @click="prevPage">
        <span aria-hidden="true"><i class="fa fa-angle-double-left" aria-hidden="true"></i></span>
      </a>
    </li>
    <li class="page-item" :class="{active: value === item}" v-for="item in range(minPage, maxPage)">
      <a class="page-link" @click="changePage(item)">{{item}}</a>
    </li>
    <li class="page-item next-page" :class="{disabled: value === totalPages}">
      <a class="page-link" aria-label="Next" @click="nextPage">
        <span aria-hidden="true"><i class="fa fa-angle-double-right" aria-hidden="true"></i></span>
      </a>
    </li>
  </ul>
</template>


<script>
  import dtHelper from 'src/helpers/datatable'

  export default {
    name: 'p-pagination',
    props: {
      type: {
        type: String,
        default: 'default',
        description: 'Pagination type (primary|info|danger|default|warning|success)'
      },
      pageCount: {
        type: Number,
        default: 0,
        description: 'Pagination page count. This should be specified in combination with perPage'
      },
      perPage: {
        type: Number,
        default: 10,
        description: 'Pagination per page. Should be specified with total or pageCount'
      },
      total: {
        type: Number,
        default: 0,
        description: 'Can be specified instead of pageCount. The page count in this case will be total/perPage'
      },
      value: {
        type: Number,
        default: 1,
        description: 'Pagination value'
      },
      checkPaginate: {
        type: Boolean,
        default: false,
        description: 'Check if used paginate in server'
      },
      paginateType: {
        type: String,
        default: '',
        description: 'Paginate type (orders, users)'
      },
    },
    computed: {
      paginationClass() {
        return `pagination-${this.type}`
      },
      totalPages() {
        if (this.pageCount > 0) return this.pageCount
        if (this.total > 0) {
          return Math.ceil(this.total / this.perPage)
        }
        return 1
      },
      pagesToDisplay() {
        if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
          return this.totalPages
        }
        return this.defaultPagesToDisplay
      },
      minPage() {
        if (this.value >= this.pagesToDisplay) {
          const pagesToAdd = Math.floor(this.pagesToDisplay / 2)
          const newMaxPage = pagesToAdd + this.value
          if (newMaxPage > this.totalPages) {
            return this.totalPages - this.pagesToDisplay + 1
          }
          return this.value - pagesToAdd
        } else {
          return 1
        }
      },
      maxPage() {
        if (this.value >= this.pagesToDisplay) {
          const pagesToAdd = Math.floor(this.pagesToDisplay / 2)
          const newMaxPage = pagesToAdd + this.value
          if (newMaxPage < this.totalPages) {
            return newMaxPage
          } else {
            return this.totalPages
          }
        } else {
          return this.pagesToDisplay
        }
      }
    },
    data() {
      return {
        defaultPagesToDisplay: 5
      }
    },
    methods: {
      range(min, max) {
        let arr = []
        for (let i = min; i <= max; i++) {
          arr.push(i)
        }
        return arr
      },
      changePage(item) {
        this.$emit('input', item)
      },
      nextPage() {
        if (this.value < this.totalPages) {
          this.$emit('input', this.value + 1)
        }
      },
      prevPage() {
        if (this.value > 1) {
          this.$emit('input', this.value - 1)
        }
      },
      getData(newVal) {
        if (this.paginateType) {
          if (this.paginateType == 'orders') {
            if (this.$store.state.currentFilterOrders) {
              let filterString = dtHelper.buildQueryString(this.$store.state.currentFilterOrders);
              this.$store.dispatch('fetchOrders', {
                filterString: filterString,
                page: newVal,
                perpage: this.perPage
              });
            } else {
              this.$store.dispatch('fetchOrders', {
                page: newVal,
                perpage: this.perPage
              });
            }
          } else if (this.paginateType == 'users') {
            if (this.$store.state.currentFilterUsers) {
              let filterString = dtHelper.buildQueryString(this.$store.state.currentFilterUsers);
              this.$store.dispatch('fetchUsers', {
                filterString: filterString,
                page: newVal,
                perpage: this.perPage
              });
            } else {
              this.$store.dispatch('fetchUsers', {
                page: newVal,
                perpage: this.perPage
              });
            }
          } else if (this.paginateType == 'bills') {
            this.$store.dispatch('fetchAllBills', {
              race_id: this.$store.state.race_id,
              page: newVal,
              perpage: this.perPage
            });
          }
        }
      }
    },
    watch: {
      perPage(newVal) {
        this.getData(newVal);
        this.$emit('input', 1);
      },
      total(newVal) {
        this.$emit('input', 1)
      },
      "value": function(newVal, oldVal) {
        this.getData(newVal);
      }
    }
  }
</script>
