var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid" }, [
    _c("h4", { staticClass: "card-title" }, [_vm._v("Sweet Alert 2")]),
    _vm._m(0),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "places-sweet-alerts" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "card",
              { attrs: { "card-body-classes": "text-center" } },
              [
                _c("p", { staticClass: "card-text" }, [
                  _vm._v("Basic example"),
                ]),
                _c(
                  "p-button",
                  {
                    attrs: { type: "default", round: "", outline: "" },
                    on: {
                      click: function ($event) {
                        return _vm.showSwal("basic")
                      },
                    },
                  },
                  [_vm._v("Try me!")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "card",
              { attrs: { "card-body-classes": "text-center" } },
              [
                _c("p", { staticClass: "card-text" }, [
                  _vm._v("A title with a text under"),
                ]),
                _c(
                  "p-button",
                  {
                    attrs: { type: "default", round: "", outline: "" },
                    on: {
                      click: function ($event) {
                        return _vm.showSwal("title-and-text")
                      },
                    },
                  },
                  [_vm._v("Try me!")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "card",
              { attrs: { "card-body-classes": "text-center" } },
              [
                _c("p", { staticClass: "card-text" }, [
                  _vm._v("A success message"),
                ]),
                _c(
                  "p-button",
                  {
                    attrs: { type: "default", round: "", outline: "" },
                    on: {
                      click: function ($event) {
                        return _vm.showSwal("success-message")
                      },
                    },
                  },
                  [_vm._v("Try me!")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "card",
              { attrs: { "card-body-classes": "text-center" } },
              [
                _c("p", { staticClass: "card-text" }, [
                  _vm._v("Custom HTML description"),
                ]),
                _c(
                  "p-button",
                  {
                    attrs: { type: "default", round: "", outline: "" },
                    on: {
                      click: function ($event) {
                        return _vm.showSwal("custom-html")
                      },
                    },
                  },
                  [_vm._v("Try me!")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "card",
              { attrs: { "card-body-classes": "text-center" } },
              [
                _c("p", { staticClass: "card-text" }, [
                  _vm._v(
                    'A warning message, with a function attached to the "Confirm" Button...'
                  ),
                ]),
                _c(
                  "p-button",
                  {
                    attrs: { type: "default", round: "", outline: "" },
                    on: {
                      click: function ($event) {
                        return _vm.showSwal("warning-message-and-confirmation")
                      },
                    },
                  },
                  [_vm._v("\n              Try me!\n            ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "card",
              { attrs: { "card-body-classes": "text-center" } },
              [
                _c("p", { staticClass: "card-text" }, [
                  _vm._v(
                    '...and by passing a parameter, you can execute something else for "Cancel"'
                  ),
                ]),
                _c(
                  "p-button",
                  {
                    attrs: { type: "default", round: "", outline: "" },
                    on: {
                      click: function ($event) {
                        return _vm.showSwal("warning-message-and-cancel")
                      },
                    },
                  },
                  [_vm._v("Try me!")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "card",
              { attrs: { "card-body-classes": "text-center" } },
              [
                _c("p", { staticClass: "card-text" }, [
                  _vm._v("A message with auto close timer set to 2 seconds"),
                ]),
                _c(
                  "p-button",
                  {
                    attrs: { type: "default", round: "", outline: "" },
                    on: {
                      click: function ($event) {
                        return _vm.showSwal("auto-close")
                      },
                    },
                  },
                  [_vm._v("Try me!")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "card",
              { attrs: { "card-body-classes": "text-center" } },
              [
                _c("p", { staticClass: "card-text" }, [
                  _vm._v("Modal window with input field"),
                ]),
                _c(
                  "p-button",
                  {
                    attrs: { type: "default", round: "", outline: "" },
                    on: {
                      click: function ($event) {
                        return _vm.showSwal("input-field")
                      },
                    },
                  },
                  [_vm._v("Try me!")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "category" }, [
      _vm._v(
        "A beautiful plugin, that replace the classic alert, Handcrafted by our friend "
      ),
      _c(
        "a",
        { attrs: { target: "_blank", href: "https://twitter.com/t4t5" } },
        [_vm._v("Tristan Edwards")]
      ),
      _vm._v(". Please check out the "),
      _c(
        "a",
        {
          attrs: {
            href: "http://limonte.github.io/sweetalert2/",
            target: "_blank",
          },
        },
        [_vm._v("full documentation.")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }