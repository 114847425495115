var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h5", { staticClass: "card-title" }, [
                _vm._v("Paper Table Heading"),
              ]),
              _c("p", { staticClass: "card-category" }, [
                _vm._v("Created using Montserrat Font Family"),
              ]),
            ]),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "typography-line" }, [
                _c("h1", [
                  _c("span", [_vm._v("Header 1")]),
                  _vm._v("The Life of Paper Dashboard "),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("h2", [
                  _c("span", [_vm._v("Header 2")]),
                  _vm._v("The Life of Paper Dashboard "),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("h3", [
                  _c("span", [_vm._v("Header 3")]),
                  _vm._v("The Life of Paper Dashboard "),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("h4", [
                  _c("span", [_vm._v("Header 4")]),
                  _vm._v("The Life of Paper Dashboard "),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("h5", [
                  _c("span", [_vm._v("Header 5")]),
                  _vm._v("The Life of Paper Dashboard "),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("h6", [
                  _c("span", [_vm._v("Header 6")]),
                  _vm._v("The Life of Paper Dashboard "),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("p", [
                  _c("span", [_vm._v("Paragraph")]),
                  _vm._v(
                    "\n              I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at.\n            "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("span", [_vm._v("Quote")]),
                _c("blockquote", [
                  _c("p", { staticClass: "blockquote blockquote-primary" }, [
                    _vm._v(
                      '\n                "I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at."\n                '
                    ),
                    _c("br"),
                    _c("br"),
                    _c("small", [
                      _vm._v("\n                  - Noaa\n                "),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("span", [_vm._v("Muted Text")]),
                _c("p", { staticClass: "text-muted" }, [
                  _vm._v(
                    "\n              I will be the leader of a company that ends up being worth billions of dollars, because I got the answers...\n            "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("span", [_vm._v("Primary Text")]),
                _c("p", { staticClass: "text-primary" }, [
                  _vm._v(
                    "\n              I will be the leader of a company that ends up being worth billions of dollars, because I got the answers..."
                  ),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("span", [_vm._v("Info Text")]),
                _c("p", { staticClass: "text-info" }, [
                  _vm._v(
                    "\n              I will be the leader of a company that ends up being worth billions of dollars, because I got the answers... "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("span", [_vm._v("Success Text")]),
                _c("p", { staticClass: "text-success" }, [
                  _vm._v(
                    "\n              I will be the leader of a company that ends up being worth billions of dollars, because I got the answers... "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("span", [_vm._v("Warning Text")]),
                _c("p", { staticClass: "text-warning" }, [
                  _vm._v(
                    "\n              I will be the leader of a company that ends up being worth billions of dollars, because I got the answers...\n            "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("span", [_vm._v("Danger Text")]),
                _c("p", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n              I will be the leader of a company that ends up being worth billions of dollars, because I got the answers... "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "typography-line" }, [
                _c("h2", [
                  _c("span", [_vm._v("Small Tag")]),
                  _vm._v(
                    "\n              Header with small subtitle\n              "
                  ),
                  _c("br"),
                  _c("small", [_vm._v('Use "small" tag for the headers')]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }