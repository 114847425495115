import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=07cb2269&"
import script from "./AppFooter.vue?vue&type=script&lang=js&"
export * from "./AppFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/truyen/workspace/dlvn/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07cb2269')) {
      api.createRecord('07cb2269', component.options)
    } else {
      api.reload('07cb2269', component.options)
    }
    module.hot.accept("./AppFooter.vue?vue&type=template&id=07cb2269&", function () {
      api.rerender('07cb2269', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dashboard/Views/Sample-Pages/Layout/AppFooter.vue"
export default component.exports