export default {
  buildRules(schemas) {
    let arr = [];
    for (let x of schemas) {
      if (x.ignoreSearch) continue;
      if (x.type === 'image') continue;

      let obj = {
        name: x.label || x.name,
        value: x.prop,
        type: x.type
      }

      switch (x.type) {
        case 'text':
          obj = Object.assign(obj, {
            ops: [
              {name: 'có chứa', value: '&='},
              {name: 'không chứa', value: '!='},
              {name: 'bằng', value: '=='},
            ]
          })
          break;

        case 'id':
        case 'number':
          obj = Object.assign(obj, {
            ops: [
              {name: '=', value: '=='},
              {name: '>', value: '>'},
              {name: '>=', value: '>='},
              {name: '<', value: '<'},
              {name: '<=', value: '<='}
            ]
          });
          break;

        case 'select':
          obj = Object.assign(obj, {
            ops: [ {name: 'bằng', value: '=='} ],
            values: x.options
          });
          break;
      }
      arr.push(obj);
    }
    return arr;
  },

  buildColumDefs(schemas) {
    return schemas.map(x => {
      let r = Object.assign({}, x);
      if (r.type == 'select') {
        r.type = 'badge'
      }
      return r;
    })
  },

  filterByRules(rows, rules) {
    if (rules.length) {
      for (let rule of rules) {
        rows = rows.filter(function (row) {
          // define special rules base on each filter
          if (rule.type === 'number' || rule.type === 'id') {
            let equation = 'row.' + rule.filter + rule.ope + rule.value;
            let fs = eval(equation);
            return fs;
          }
          if (rule.type === 'text' || rule.type === 'select') {
            let cellFilter = row[rule.filter];
            if (cellFilter && rule.value) {
              if (rule.ope === '&=') {
                let fs = cellFilter.toLowerCase().indexOf(rule.value.toLowerCase()) > -1;
                return fs;
              } else if (rule.ope === '!=') {
                let fs = cellFilter.toLowerCase().indexOf(rule.value.toLowerCase()) == -1
                return fs;
              } else {
                let fs = cellFilter.toLowerCase() == rule.value.toLowerCase();
                return fs;
              }
            }
            return false;
          }
          return false;
        })
      }
    }
    return rows;
  },


  buildQueryString(rules) {
    let queryString = '';
    let arr = [];
    if (rules.length) {
      for (let rule of rules) {
        if (rule.ope == '&=') rule.ope = '**';
        arr.push(`${rule.filter}${rule.ope}${rule.value}`);
      }
      queryString = arr.join('&');
    }
    return queryString;
  }
}
