var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-page" },
    [
      _c("app-navbar"),
      _c("div", { staticClass: "wrapper wrapper-full-page" }, [
        _c(
          "div",
          {
            staticClass: "full-page register-page section-image",
            attrs: { "filter-color": "black" },
          },
          [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-5 col-md-5 ml-auto" },
                    [
                      _c("info-section", {
                        staticClass: "mt-5",
                        attrs: {
                          type: "primary",
                          title: "Marketing",
                          description:
                            "We've created the marketing campaign of the website. It was a very interesting collaboration.",
                          icon: "nc-icon nc-tv-2",
                        },
                      }),
                      _c("info-section", {
                        attrs: {
                          type: "primary",
                          title: "Fully Coded in HTML5",
                          description:
                            "We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub.",
                          icon: "nc-icon nc-html5",
                        },
                      }),
                      _c("info-section", {
                        attrs: {
                          type: "primary",
                          title: "Built Audience",
                          description:
                            "There is also a Fully Customizable CMS Admin Dashboard for this product.",
                          icon: "nc-icon nc-atom",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-4 col-md-6 mr-auto" },
                    [
                      _c(
                        "card",
                        {
                          staticClass: "text-center",
                          attrs: { type: "signup" },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v("Register"),
                            ]),
                            _c("div", { staticClass: "social" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-icon btn-round btn-twitter",
                                },
                                [_c("i", { staticClass: "fa fa-twitter" })]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-icon btn-round btn-dribbble",
                                },
                                [_c("i", { staticClass: "fa fa-dribbble" })]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-icon btn-round btn-facebook",
                                },
                                [_c("i", { staticClass: "fa fa-facebook-f" })]
                              ),
                              _c("p", { staticClass: "card-description" }, [
                                _vm._v(" or be classical "),
                              ]),
                            ]),
                          ]),
                          _c("fg-input", {
                            attrs: {
                              "addon-left-icon": "nc-icon nc-single-02",
                              placeholder: "First Name...",
                            },
                            model: {
                              value: _vm.form.firstName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "firstName", $$v)
                              },
                              expression: "form.firstName",
                            },
                          }),
                          _c("fg-input", {
                            attrs: {
                              "addon-left-icon": "nc-icon nc-circle-10",
                              placeholder: "Last Name...",
                            },
                            model: {
                              value: _vm.form.lastName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "lastName", $$v)
                              },
                              expression: "form.lastName",
                            },
                          }),
                          _c("fg-input", {
                            attrs: {
                              "addon-left-icon": "nc-icon nc-email-85",
                              placeholder: "Email...",
                            },
                            model: {
                              value: _vm.form.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email",
                            },
                          }),
                          _c(
                            "p-checkbox",
                            {
                              staticClass: "text-left",
                              model: {
                                value: _vm.form.acceptTerms,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "acceptTerms", $$v)
                                },
                                expression: "form.acceptTerms",
                              },
                            },
                            [
                              _vm._v(
                                "\n                  I agree to the\n                  "
                              ),
                              _c("a", { attrs: { href: "#something" } }, [
                                _vm._v("terms and conditions"),
                              ]),
                              _vm._v(".\n                "),
                            ]
                          ),
                          _c(
                            "p-button",
                            {
                              attrs: {
                                slot: "footer",
                                type: "info",
                                round: "",
                              },
                              slot: "footer",
                            },
                            [_vm._v("Get Started")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("app-footer"),
            _c("div", {
              staticClass: "full-page-background",
              staticStyle: {
                "background-image":
                  "url(static/img/background/jan-sendereks.jpg)",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }