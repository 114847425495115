import * as types from './mutation-types'
import api from './api';
import util from '../helpers/util'
import {router} from "../main.js"

function buildQuery(params) {
  if (!params) {
    return '';
  }
  if (typeof params === 'string') {
    return params;
  }
  if (typeof params !== 'object') {
    return '';
  }

  let arr = [];
  for (let key of Object.keys(params)) {
    if (typeof params[key] != 'undefined') {
      arr.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
    }
  }
  return '?' + arr.join('&');
}

export const actions = {

  authLogin(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/login', data)
        .then(resp => {
          let data = resp.data;
          if (!data.code) {
            commit(types.AUTH_SUCCESS, data);
            resolve(data);
          } else{
            resolve(data);
          }
        }, error => {
          commit(types.AUTH_ERROR, error)
          reject(error);
        });
    });
  },

  authLogout(context) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      commit(types.LOGOUT);
      resolve({
        message: 'Thành công'
      });
    })
  },

  //users
  fetchUsers(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/users' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_USERS, data);
    })
  },

  fetchMailTypes(context) {
    let {commit, state} = context;
    api.get('/api/admin/mail/types')
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_MAIL_TYPES, data);
    })
  },

  fetchLogs(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/logs' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_ACTIVITIES, data);
    })
  },

  fetchActivities(context, params) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/activities' + buildQuery(params))
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  fetchActivityDetail(context, detail_id) {
    let {commit, state} = context;
    api.get('/api/admin/activities/' + detail_id)
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_ACTIVITY_DETAIL, data.data);
      })
  },

  updateActivityDetail(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/activities/' + data.id, data)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  updateDonateInfo(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/donates/' + data.id, data)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  updateOrderProduct(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/addon/orders/' + data.id, data)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  updateOrderVip(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/vip/orders/' + data.id, data)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  updateOrderEnterprise(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/enterprise/orders/' + data.id, data)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  searchUserEmail(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/user/email', data)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  updateUserPassword(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/user/change-password/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  updateShippingOrderLine(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put(`/api/admin/order/orderlines/${form.order_line_id}/shipping/`, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  disconnectStrava(context, user_id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/user/disconnect-strava/' + user_id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  syncUserActivities(context, obj) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/user/sync-activities', obj)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  syncUserAllActivities(context, obj) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/user/sync-activities/all', obj)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  pushActivityToBIDV(context, obj) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/user/push-activity-bidv', obj)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  getUserInfo(context, obj) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/user/support-tool', obj)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  getUserInfoBIDV(context, obj) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/user/support-tool-bidv', obj)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  fetchUserDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/users/' + id)
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data.data;
      commit(types.FETCH_USER_DETAIL, data);
    })
  },

  fetchAllSetting(context) {
    let {commit, state} = context;
    api.get('/api/admin/setting/all')
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_ALL_SETTING, data);
    })
  },

  fetchAllCaching(context) {
    let {commit, state} = context;
    api.get('/api/admin/caching')
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_ALL_CACHING, data);
    })
  },

  updateUser(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/users/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  calcDistanceUserRace(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/user/calcDistance', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  resendEmailCompleted(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/users/resendEmailCompleted', data)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  disconnectDcAccount(context, data) {
        let {commit, state} = context;
        return new Promise((resolve, reject) => {
            api.post('/api/admin/users/remove-dc-account', data)
                .then(resp => {
                    resolve(resp.data);
                }, error => {
                    reject(error);
                });
        });
  },

  addYelloCard(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/users/addYellowCard', data)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  clearCache(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/caching/clear', data)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeUser(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.delete('/api/admin/user/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  fetchCharities(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/charity/list' + buildQuery(params))
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    }).then(resp => {
      let data = resp.data;
      commit(types.FETCH_CHARITIES, data.data);
    })
  },

  fetchPackages(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/enterprise/packages' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    }).then(res => {
      let data = res.data;
      commit(types.FETCH_PACKAGES, data.data);
    })
  },

  fetchOrderEnterprises(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/enterprise/orders' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    }).then(res => {
      let data = res.data;
      commit(types.FETCH_ORDER_ENTERPRISES, data.data);
    })
  },

  storeCharity(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/charity/add', form)
      .then(res => {
        resolve(res.data);
      }, error => {
        reject(error);
      });
    });
  },

  fetchCharityDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/charity/charity/' + id)
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    }).then(resp => {
      let data = resp.data;
      commit(types.FETCH_CHARITY_DETAIL, data);
    })
  },

  fetchPackageDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/enterprise/packages/' + id)
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    }).then(resp => {
      let data = resp.data;
      commit(types.FETCH_PACKAGE_DETAIL, data.data);
    })
  },

  updateCharity(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/charity/edit/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  updatePackageEnterprise(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/enterprise/packages/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  updateVip(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/vip/' + data.id, data)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  updateSetting(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/setting/update', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeCharity(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/charity/delete/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeEnterprisePackage(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.delete('/api/admin/enterprise/packages/' + id)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },


  //races
  fetchRaces(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/race/list' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_RACES, data.data);
    })
  },

  fetchAllRaces(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/race/list/all' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_ALL_RACES, data.data);
    })
  },

  fetchRacesShort(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/race/list/short' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_ALL_RACES_SHORT, data.data);
    })
  },


  fetchAllEnterpriseRaces(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/enterprise/races' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_ALL_ENTERPRISE_RACES, data.data);
    })
  },

  fetchRaceDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/races/' + id)
    .catch(err => {
      console.error(err);
    }).then(resp => {
      let data = resp.data;
      commit(types.FETCH_RACE_DETAIL, data);
    })
  },

  updateRace(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/races/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  getUserOrder(context, obj) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/users/orders', obj)
        .then(res => {
          resolve(res.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeRace(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.delete('/api/admin/races/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  storeMetafield(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/metafields', data)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  getMetafield(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/metafields/object' + buildQuery(params))
    .then(res => {
      let data = res.data;
      commit(types.FETCH_METAFIELD_OBJECT, data.data);
    })
  },

  fetchOrders(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/orders' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_ORDERS, data);
    })
  },

  fetchDonates(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/donates' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(res => {
      let data = res.data;
      commit(types.FETCH_DONATES, data);
    })
  },

  fetchOrderProducts(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/addon/orders' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(res => {
      let data = res.data;
      commit(types.FETCH_ORDER_PRODUCTS, data);
    })
  },

  fetchOrderVips(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/vip/orders' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(res => {
      let data = res.data.data;
      commit(types.FETCH_ORDER_VIPS, data);
    })
  },

  fetchOrderProductDetail(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/addon/orders/' + id)
      .then(res => {
        resolve(res.data);
      }, error => {
        reject(error);
      });
    });
  },

  fetchOrderVipDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/vip/orders/' + id)
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_ORDER_VIP_DETAIL, data.data);
      })
  },

  exportOrderProduct(context, params) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/addon/orders/export' + buildQuery(params))
      .then(res => {
        resolve(res.data);
      }, error => {
        reject(error);
      });
    });
  },

  exportOrderVip(context, params) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/vip/orders/export' + buildQuery(params))
      .then(res => {
        resolve(res.data);
      }, error => {
        reject(error);
      });
    });
  },

  fetchBills(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/bills/index')
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_BILLS, data);
    })
  },

  fetchAllBills(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/bills/orders' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_ALL_BILLS, data);
      commit(types.RACE_ID, params.race_id);
    })
  },

  fetchActivitiesCheat(context, params) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/activities/cheat' + buildQuery(params))
        .then(resp => {
          let data = resp.data;
          commit(types.FETCH_ALL_ACTIVITIES_CHEAT, data);
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  fetchOrderDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/orders/' + id)
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_ORDER_DETAIL, data);
      })
  },

  fetchOrderEnterpriseDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/enterprise/orders/' + id)
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_ORDER_ENTERPRISE_DETAIL, data.data);
      })
  },

  updateOrder(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/orders/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  updateOrderLine(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/orderlines/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  importShippingStatus(context, formData) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/order/import-shipping-status', formData)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  importCoupon(context, formData) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/coupons/import', formData)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  exportShippingStatus(context, orders) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/order/export-shipping-status', orders)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  exportOperationShip(context, orders) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/operation-ship/export', orders)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  importOperationShip(context, formData) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/operation-ship/import', formData)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  importMailMerge(context, formData) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/mail-merge/import', formData)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  sendMailMerge(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/mail-merge/send', data)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
    });
  },

  updateTransporters(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/bills/updateTransporters', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  resendEmail(context, order_id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/order/resend-email/' + order_id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  sendEmailUserShipping(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/operation-ship/send-manual-email', data)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  resendEmailOrderEnterprise(context, order_id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/enterprise/resend-email/' + order_id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  resendEmailOrderVip(context, order_id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/vip/resend-email/' + order_id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  fetchCustomers(context) {
    let {commit, state} = context;
    api.get('/admin/api/customer')
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_CUSTOMER, data.data);
    })
  },

  fetchShippingStatus(context) {
    let {commit, state} = context;
    api.get('/api/admin/order/shipping-status')
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_SHIPPING_STATUS, data.data);
    })
  },

  getShippingConfig(context) {
    let {commit, state} = context;
    api.get('/api/admin/shipping-config')
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_SHIPPING_CONFIG, data.data);
    })
  },

  storeUser(context, form) {
    let {commit, state} = context;
    api.post('/admin/api/user', form)
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      if (!data.code) {
        commit(types.MESSAGE, {
          message: 'Thành công',
          type: 'error'
        });
        commit(types.STORE_USER, {
          form: form,
          data: data
        });
      } else{
        commit(types.MESSAGE, {
          message: 'Có lỗi xảy ra, xin vui lòng thử lại',
          type: 'error'
        });
      }
    })
  },

  fetchHistorys(context) {
    let {commit, state} = context;
    api.get('/admin/api/history')
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_HISTORY, data.data);
    })
  },

  fetchShippings(context) {
    let {commit, state} = context;
    api.get('/admin/api/shippings')
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_SHIPPING, data.data);
    })
  },

  fetchRoles(context) {
    let {commit, state} = context;
    api.get('/admin/api/roles')
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_ROLE, data.data);
    })
  },

  fetchDeleted(context) {
    let {commit, state} = context;
    api.get('/admin/api/deleted')
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_DELETED, data.data);
    })
  },

  setCurrentActions(context, actions) {
    let {commit, state} = context;
    commit(types.SET_CURRENT_ACTIONS, actions);
  },

  setCurrentFilterOrders(context, data) {
    let {commit, state} = context;
    commit(types.SET_CURRENT_FILTER_ORDERS, data);
  },

  setCurrentFilterUsers(context, data) {
    let {commit, state} = context;
    commit(types.SET_CURRENT_FILTER_USERS, data);
  },

  setFilterTags(context, data) {
    let {commit, state} = context;
    commit(types.SET_FILTER_TAGS, data);
  },

  setPageTitle(context, title) {
    let {commit, state} = context;
    commit(types.SET_PAGE_TITLE, title);
  },

  createObject(context, link) {
    let {commit, state} = context;
    router.push(link);
  },

  // addons
  fetchAddons(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/addons' + buildQuery(params))
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_ADDONS, data.data);
      })
  },

  fetchAddonDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/addons/' + id)
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_ADDON_DETAIL, data);
    })
  },

  fetchCatalogDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/catalog/show/' + id)
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_CATALOG_DETAIL, data);
    })
  },

  storeAddon(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/addons', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  storeEnterprisePackage(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/enterprise/packages', form)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  storeVip(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/vip', data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  storeCatalog(context, form) {
    let {commit, state} = context;
    api.post('/api/admin/catalog/add', form)
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      if (!data.code) {
        commit(types.MESSAGE, {
          message: 'Thành công',
          type: 'addon'
        });
      } else{
        commit(types.MESSAGE, {
          message: 'Có lỗi xảy ra, xin vui lòng thử lại',
          type: 'error'
        });
      }
    })
  },

  updateAddon(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/addons/' + form.id, form)
        .then(resp => {
          let data = resp.data;
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  },

  updateCatalog(context, form) {
    let {commit, state} = context;
    api.post('/api/admin/catalog/update/' + form.id, form)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        if (!data.code) {
          commit(types.MESSAGE, {
            message: 'Thành công',
            type: 'error'
          });
        } else{
          commit(types.MESSAGE, {
            message: 'Có lỗi xảy ra, xin vui lòng thử lại',
            type: 'error'
          });
        }
      })
  },

  removeAddon(context, id) {
    let {commit, state} = context;
    api.delete('/api/admin/addons/' + id)
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    })
    .then(resp => {
      commit(types.REMOVE_ADDON, {
        id: id
      });
    })
  },

  removeCatalog(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/catalog/delete/' + id)
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    })
    .then(resp => {
      commit(types.REMOVE_CATALOG, {
        id: id
      });
    })
  },

  fetchRules(context) {
    let {commit, state} = context;
    api.get('/api/admin/rule/list')
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_RULES, data);
      })
  },

  fetchVip(context) {
    let {commit, state} = context;
    api.get('/api/admin/vip')
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_VIPS, data.data);
      })
  },

  fetchRaceRules(context) {
    let {commit, state} = context;
    api.get('/api/admin/race/rules')
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_RACE_RULES, data.data);
      })
  },

  fetchAllWorkouts(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/workouts' + buildQuery(params))
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_WORKOUTS, data.data);
      })
  },

  fetchAllWalking(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/walking' + buildQuery(params))
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_WALKING, data.data);
      })
  },

  fetchRuleDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/rule/rule/' + id)
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_RULE_DETAIL, data);
      })
  },

  fetchVipDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/vip/' + id)
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_VIP_DETAIL, data.data);
      })
  },

  storeRule(context, form) {
    let {commit, state} = context;
    api.post('/api/admin/rule/add', form)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        if (!data.code) {
          commit(types.MESSAGE, {
            message: 'Thành công',
            type: 'addon'
          });
          commit(types.STORE_RULE, {
            form: form,
            data: data
          });
        } else{
          commit(types.MESSAGE, {
            message: 'Có lỗi xảy ra, xin vui lòng thử lại',
            type: 'error'
          });
        }
      })
  },
  updateRule(context, form) {
    let {commit, state} = context;
    api.post('/api/admin/rule/edit/' + form.id, form)
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        if (!data.code) {
          commit(types.MESSAGE, {
            message: 'Thành công',
            type: 'error'
          });
        } else{
          commit(types.MESSAGE, {
            message: 'Có lỗi xảy ra, xin vui lòng thử lại',
            type: 'error'
          });
        }
      })
  },
  removeRule(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/rule/delete/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        commit(types.REMOVE_RULE, {
          id: id
        });
      })
  },

  // themes
  fetchThemes(context) {
    let {commit, state} = context;
    api.get('/api/admin/theme/list')
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_THEMES, data);
      })
  },
  fetchThemeDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/theme/theme/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_THEME_DETAIL, data);
      })
  },
  storeTheme(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/theme/add', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    })
  },
  updateTheme(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/theme/edit/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },
  removeTheme(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/theme/delete/' + id)
        .catch(err => {
          console.error(err);
          console.error(err.stack);
        })
        .then(resp => {
          commit(types.REMOVE_THEME, {
            id: id
          });
          resolve(resp.data);
        })
    })
  },

  //noti
  fetchNotifications(context) {
    let {commit, state} = context;
    api.get('/api/admin/notification/list')
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_NOTIFICATIONS, data);
      })
  },

  storeNotification(context, form) {
    let {commit, state} = context;
    api.post('/api/admin/notification/add', form)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        if (!data.code) {
          commit(types.STORE_NOTIFICATION, {
            form: form,
            data: data
          });
        } else{
          commit(types.MESSAGE, {
            message: 'Có lỗi xảy ra, xin vui lòng thử lại',
            type: 'error'
          });
        }
      })
  },

  fetchRaceTypes(context) {
    let {commit, state} = context;
    api.get('/api/admin/race/types')
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_RACE_TYPES, data);
    });
  },
  fetchRaceTypeDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/race/type/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_RACE_TYPE_DETAIL, data);
      })
  },
  storeRaceTypes(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/race/type', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    })
  },
  updateRaceType(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/race/type/edit/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },
  removeRaceTypes(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/race/type/delete/' + id)
        .catch(err => {
          console.error(err);
          console.error(err.stack);
        })
        .then(resp => {
          commit(types.REMOVE_RACE_TYPE, {
            id: id
          });
          resolve(resp.data);
        })
    })
  },

  removeActivity(context, data) {
    return new Promise((resolve, reject) => {
      api.post('/api/admin/activities/delete', data)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  deleteActivity(context, data) {
    return new Promise((resolve, reject) => {
      api.post('/api/admin/activities/delete', data)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  deleteWorkout(context, obj) {
    return new Promise((resolve, reject) => {
      api.delete('/api/admin/workouts/' + obj.id + '?type=' + obj.type)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  deleteWalking(context, obj) {
    return new Promise((resolve, reject) => {
      api.delete('/api/admin/walking/' + obj.id + '?type=' + obj.type)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  //race cate
  fetchRaceCategories(context) {
    let {commit, state} = context;
    api.get('/api/admin/race/categories')
    .catch(err => {
      console.error(err);
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_RACE_CATEGORIES, data);
    });
  },
  fetchRaceCategoryDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/race/category/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_RACE_CATEGORY_DETAIL, data);
      })
  },
  storeRaceCategory(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/race/category', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    })
  },
  updateRaceCategory(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/race/category/edit/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },
  removeRaceCategory(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/race/category/delete/' + id)
        .catch(err => {
          console.error(err);
          console.error(err.stack);
        })
        .then(resp => {
          commit(types.REMOVE_RACE_CATEGORY, {
            id: id
          });
          resolve(resp.data);
        })
    })
  },

  fetchRaceCatalogs(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/race/catalogs' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_RACE_CATALOGS, data);
    });
  },

  fetchRaceAddons(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/race/addons' + buildQuery(params))
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_RACE_ADDONS, data);
    });
  },

  getRaceAddons(context, race_id) {
    let { commit, state } = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/race/addons?race_id=' + race_id)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err);
      })
    })
  },

  storeRace(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/races', form)
      .then(res => {
        resolve(res.data);
      }, error => {
        reject(error);
      });
    });
  },

  updatePassword(context, data) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/change-password', data)
      .then(res => {
        resolve(res.data);
      }, error => {
        reject(error);
      });
    });
  },


  uploadImage(context, formData) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/uploadImage', formData)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },


  fetchCoupons(context) {
    let {commit, state} = context;
    api.get('/api/admin/coupons')
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_COUPONS, data);
      })
  },

  fetchClubs(context) {
    let {commit, state} = context;
    api.get('/api/admin/club/list')
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_CLUBS, data.data);
      })
  },

  fetchLocations(context, params) {
    let {commit, state} = context;
    api.get('/api/admin/locations' + buildQuery(params))
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_LOCATIONS, data.data);
      })
  },

  fetchCompanies(context) {
    let {commit, state} = context;
    api.get('/api/admin/company/list')
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_COMPANIES, data);
      })
  },

  fetchGroups(context) {
    let {commit, state} = context;
    api.get('/api/admin/groups')
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_GROUPS, data.data);
      })
  },

  fetchSchools(context) {
    let {commit, state} = context;
    api.get('/api/admin/school/list')
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_SCHOOLS, data);
      })
  },

  fetchCouponDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/coupons/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_COUPON_DETAIL, data.data);
      })
  },

  fetchClubDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/club/edit/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_CLUB_DETAIL, data.data);
      })
  },

  fetchLocationDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/locations/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_LOCATION_DETAIL, data.data);
      })
  },

  fetchCompanyDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/company/edit/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_COMPANY_DETAIL, data.data);
      })
  },

  fetchGroupDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/groups/' + id)
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_GROUP_DETAIL, data.data);
      })
  },

  fetchSchoolDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/school/edit/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_SCHOOL_DETAIL, data.data);
      })
  },

  storeCoupon(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/coupons', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  storeClub(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/club/add', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  storeLocation(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/locations', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  storeCompany(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/company/add', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  storeGroup(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/groups', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  storeSchool(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/school/add', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  updateCoupon(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/coupons/' + form.id, form)
        .then(resp => {
          let data = resp.data;
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  },

  updateClub(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/club/edit/' + form.id, form)
        .then(resp => {
          let data = resp.data;
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  },

  updateLocation(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/locations/' + form.id, form)
        .then(resp => {
          let data = resp.data;
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  },

  updateCompany(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/company/edit/' + form.id, form)
        .then(resp => {
          let data = resp.data;
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  },

  updateGroup(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/groups/' + form.id, form)
        .then(resp => {
          let data = resp.data;
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  },

  updateSchool(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/school/edit/' + form.id, form)
        .then(resp => {
          let data = resp.data;
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  },

  removeCoupon(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.delete('/api/admin/coupons/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeClub(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/club/delete/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeGroup(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.delete('/api/admin/groups/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeLocation(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.delete('/api/admin/locations/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeCompany(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/company/delete/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeSchool(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/school/delete/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  fetchMails(context) {
    let {commit, state} = context;
    api.get('/api/admin/mail/list')
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_MAILS, data);
      })
  },

  fetchMailForms(context) {
    let {commit, state} = context;
    api.get('/api/admin/mail/form')
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let result = resp.data;
        commit(types.FETCH_MAIL_FORMS, result.data);
      })
  },

  fetchSendMailHistory(context) {
    let {commit, state} = context;
    api.get('/api/admin/mail/send-history')
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let result = resp.data;
        commit(types.FETCH_SEND_MAIL_HISTORY, result.data);
      })
  },

  fetchMailDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/mail/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_MAIL_DETAIL, data);
      })
  },

  fetchMailFormDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/mail/form/' + id)
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_MAIL_FORM_DETAIL, data.data);
      })
  },

  storeMail(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/mail/add', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  storeMailForm(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/mail/form', form)
      .then(res => {
        resolve(res.data);
      }, error => {
        reject(error);
      });
    });
  },

  updateMail(context, form) {
    let {commit, state} = context;
    api.post('/api/admin/mail/edit/' + form.id, form)
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        if (data.success) {
          commit(types.MESSAGE, {
            message: 'Thành công',
            type: 'error'
          });
        } else{
          commit(types.MESSAGE, {
            message: 'Có lỗi xảy ra, xin vui lòng thử lại',
            type: 'error'
          });
        }
      })
  },

  updateMailForm(context, obj) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/mail/form/' + obj.id, obj.data)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeMail(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/mail/delete/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeMailForm(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.delete('/api/admin/mail/form/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  sendEmailUsers(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/mail/form/send/' + id)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  fetchProvinces(context) {
    let {commit, state} = context;
    api.get('/api/region/provinces').then(res => {
      let data = res.data;
      commit(types.FETCH_PROVINCES, data.data);
    })
  },

  fetchStaffs(context) {
    let {commit, state} = context;
    api.get('/api/admin/staffs')
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_ADMINS, data);
      })
  },

  storeStaff(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/staffs', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  fetchStaffDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/staffs/' + id)
    .catch(err => {
      console.error(err.stack);
    })
    .then(resp => {
      let data = resp.data;
      commit(types.FETCH_STAFF_DETAIL, data);
    })
  },

  updateStaff(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.put('/api/admin/staffs/' + form.id, form)
        .then(resp => {
          let data = resp.data;
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  },

  removeAdmin(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.delete('/api/admin/staffs/' + id)
        .then(resp => {
          resolve(resp.data);
        });
    });
  },

  fetchDashboard(context) {
    let {commit, state} = context;
    api.get('/api/admin/dashboard/index')
      .catch(err => {
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_DASHBOARD, data);
      })
  },

  storeRaceType(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/race/type', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  fetchDistricts(context, province_id) {
    let {commit, state} = context;
    return api.get('/api/region/districts?province_id=' + province_id).then(res => {
      let data = res.data;
      commit(types.FETCH_DISTRICTS, data.data);
    });
  },

  fetchWards(context, district_id) {
    let {commit, state} = context;
    api.get('/api/region/wards?district_id=' + district_id).then(res => {
      let data = res.data;
      commit(types.FETCH_WARDS, data.data);
    });
  },

  initSidebar(context) {
    let {commit, state} = context;
    commit(types.SIDEBAR_INIT);
  },

  fetchMenus(context) {
    let {commit, state} = context;
    api.get('/api/admin/menu/list')
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_MENUS, data);
      });
  },

  fetchMenuDetail(context, id) {
    let {commit, state} = context;
    api.get('/api/admin/menu/menu/' + id)
      .catch(err => {
        console.error(err);
        console.error(err.stack);
      })
      .then(resp => {
        let data = resp.data;
        commit(types.FETCH_MENU_DETAIL, data);
      })
  },
  storeMenu(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/menu/add', form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    })
  },
  updateMenu(context, form) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.post('/api/admin/menu/edit/' + form.id, form)
        .then(resp => {
          resolve(resp.data);
        }, error => {
          reject(error);
        });
    });
  },

  removeMenu(context, id) {
    let {commit, state} = context;
    return new Promise((resolve, reject) => {
      api.get('/api/admin/menu/delete/' + id)
        .catch(err => {
          console.error(err.stack);
        })
        .then(resp => {
          commit(types.REMOVE_MENU, {
            id: id
          });
          resolve(resp.data);
        })
    })
  },
}
