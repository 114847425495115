export const getters = {
  fetchRaces (state) {
    return state.races;
  },
  fetchRaceTypes(state) {
    return state.raceTypes;
  },
  isAuthenticated(state) {
    return !!state.token;
  },
  authStatus: state => state.status,
}
