var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.vertical ? "row" : "" }, [
    _c("div", { class: _vm.vertical ? _vm.verticalNavClasses : "" }, [
      _c(
        "div",
        {
          staticClass: "nav-tabs-navigation",
          class: { "verical-navs": _vm.vertical },
        },
        [_c("div", { staticClass: "nav-tabs-wrapper" }, [_vm._t("nav")], 2)]
      ),
    ]),
    _c(
      "div",
      { class: _vm.vertical ? _vm.verticalTabContentClasses : "" },
      [_vm._t("content")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }