var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lock-page" },
    [
      _c("app-navbar"),
      _c("div", { staticClass: "wrapper wrapper-full-page" }, [
        _c(
          "div",
          {
            staticClass: "full-page lock-page section-image",
            attrs: { "filter-color": "black" },
          },
          [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-4 col-md-6 ml-auto mr-auto" },
                  [
                    _c(
                      "card",
                      { staticClass: "text-center", attrs: { type: "lock" } },
                      [
                        _c("img", {
                          attrs: {
                            slot: "header",
                            src: "static/img/faces/joe-gardner-2.jpg",
                            alt: "...",
                          },
                          slot: "header",
                        }),
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v("Joe Gardner"),
                        ]),
                        _c("fg-input", {
                          attrs: {
                            type: "password",
                            placeholder: "Enter Password..",
                          },
                        }),
                        _c(
                          "p-button",
                          {
                            attrs: {
                              slot: "footer",
                              type: "default",
                              round: "",
                              outline: "",
                            },
                            slot: "footer",
                          },
                          [_vm._v("\n                Unlock\n              ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("app-footer"),
            _c("div", {
              staticClass: "full-page-background",
              staticStyle: {
                "background-image":
                  "url(static/img/background/bruno-abatti.jpg)",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }