var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c("div", { staticClass: "card-body" }, [
      _c("form", [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-5" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "Company",
                  disabled: true,
                  placeholder: "Paper dashboard",
                },
                model: {
                  value: _vm.user.company,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "company", $$v)
                  },
                  expression: "user.company",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "Username",
                  placeholder: "Username",
                },
                model: {
                  value: _vm.user.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "username", $$v)
                  },
                  expression: "user.username",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("fg-input", {
                attrs: {
                  type: "email",
                  label: "Username",
                  placeholder: "Email",
                },
                model: {
                  value: _vm.user.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "email", $$v)
                  },
                  expression: "user.email",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "First Name",
                  placeholder: "First Name",
                },
                model: {
                  value: _vm.user.firstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "firstName", $$v)
                  },
                  expression: "user.firstName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "Last Name",
                  placeholder: "Last Name",
                },
                model: {
                  value: _vm.user.lastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "lastName", $$v)
                  },
                  expression: "user.lastName",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "Address",
                  placeholder: "Home Address",
                },
                model: {
                  value: _vm.user.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "address", $$v)
                  },
                  expression: "user.address",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("fg-input", {
                attrs: { type: "text", label: "City", placeholder: "City" },
                model: {
                  value: _vm.user.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "city", $$v)
                  },
                  expression: "user.city",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "Country",
                  placeholder: "Country",
                },
                model: {
                  value: _vm.user.country,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "country", $$v)
                  },
                  expression: "user.country",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("fg-input", {
                attrs: {
                  type: "number",
                  label: "Postal Code",
                  placeholder: "ZIP Code",
                },
                model: {
                  value: _vm.user.postalCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "postalCode", $$v)
                  },
                  expression: "user.postalCode",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("About Me")]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.aboutMe,
                    expression: "user.aboutMe",
                  },
                ],
                staticClass: "form-control border-input",
                attrs: {
                  rows: "5",
                  placeholder: "Here can be your description",
                },
                domProps: { value: _vm.user.aboutMe },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.user, "aboutMe", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-info btn-fill btn-wd",
              attrs: { type: "submit" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.updateProfile.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n          Update Profile\n        ")]
          ),
        ]),
        _c("div", { staticClass: "clearfix" }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "title" }, [_vm._v("Edit Profile")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }