var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c("div", { staticClass: "card-body" }, [
      _c("ul", { staticClass: "list-unstyled team-members" }, [
        _c(
          "li",
          _vm._l(_vm.members, function (member) {
            return _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-2 col-md-2" }, [
                _c("div", { staticClass: "avatar" }, [
                  _c("img", {
                    staticClass: "img-circle img-no-padding img-responsive",
                    attrs: { src: member.image, alt: "Circle Image" },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "col-md-7 col-7" }, [
                _vm._v(
                  "\n            " + _vm._s(member.name) + "\n            "
                ),
                _c("br"),
                _c("span", { class: _vm.getStatusClass(member.status) }, [
                  _c("small", [_vm._v(_vm._s(member.status))]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "col-md-3 col-3 text-right" },
                [
                  _c(
                    "p-button",
                    {
                      attrs: {
                        size: "sm",
                        type: "success",
                        outline: "",
                        icon: "",
                        round: "",
                      },
                    },
                    [_c("i", { staticClass: "fa fa-envelope" })]
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }