import * as types from './mutation-types'
import util from '../helpers/util'
import sidebars from "../sidebarLinks";

export const mutations = {
  [types.SIDEBAR_INIT]: (state) => {
    if (state.role == 1) {
      state.sidebarLinks = [
        {
          name: 'Bảng Điều Khiển',
          icon: 'nc-icon nc-app',
          path: '/admin/overview'
        },
        {
          name: 'Quản lý cuộc đua',
          icon: 'nc-icon nc-user-run',
          children: [{
              name: 'Tất cả cuộc đua',
              path: '/races'
            },
            {
              name: 'Loại cuộc đua',
              path: '/category-race'
            },
            {
              name: 'Chủ đề cuộc đua',
              path: '/themes'
            },
            {
              name: 'Kiểu cuộc đua',
              path: '/race-type'
            },
            {
              name: 'Tiện ích',
              path: '/addons'
            },
            {
              name: 'Cự ly',
              path: '/catalogs'
            },
            {
              name: 'Địa điểm Offline',
              path: '/locations'
            }
          ]
        },
        {
          name: 'Quản lý người dùng',
          icon: 'nc-icon nc-badge',
          children: [{
              name: 'Tất cả người dùng',
              path: '/users'
            },
            {
              name: 'Bảng',
              path: '/groups'
            },
            {
              name: 'Câu lạc bộ',
              path: '/clubs'
            },
            {
              name: 'Công ty',
              path: '/companies'
            },
            {
              name: 'Trường học',
              path: '/schools'
            }
          ]
        },
        {
          name: 'Quản lý đơn hàng',
          icon: 'nc-icon nc-single-copy-04',
          children: [{
            name: 'Đăng ký race',
            path: '/orders'
          },
          {
            name: 'Đóng góp quỹ từ thiện',
            path: '/donates'
          },
          {
            name: 'Mua iVip',
            path: '/order-vips'
          }]
        },
        {
          name: 'Doanh nghiệp',
          icon: 'nc-icon nc-bank',
          children: [{
              name: 'Danh sách gói',
              path: '/enterprise/packages'
            },
            {
              name: 'Danh sách race',
              path: '/enterprise/races'
            },
            {
              name: 'Danh sách đơn hàng',
              path: '/enterprise/orders'
            }
          ]
        },
        {
          name: 'Quản lý Coupon',
          icon: 'nc-icon nc-tag-content',
          children: [
            {
              name: 'Tạo mới',
              path: '/coupons/create'
            },
            {
              name: 'Danh sách',
              path: '/coupons/all'
            }
          ]
        },
        {
          name: 'Vip',
          icon: 'nc-icon nc-paper',
          path: '/vip'
        },
        {
          name: 'Quản lý quy định',
          icon: 'nc-icon nc-paper',
          path: '/rules'
        },
        {
          name: 'Quản lý Mail',
          icon: 'nc-icon nc-email-85',
          children: [
            {
              name: 'Forms',
              path: '/mail/forms'
            },
            {
              name: 'Templates',
              path: '/mail/templates'
            },
            {
              name: 'Mail merge',
              path: '/mail-merge'
            },
            {
              name: 'Lịch sử gửi',
              path: '/mail/history'
            }
          ]
        },
        {
          name: 'Quản lý quỹ từ thiện',
          icon: 'nc-icon nc-alert-circle-i',
          path: '/charities'
        },
        {
          name: 'Operation & Ship',
          icon: 'nc-icon nc-bus-front-12',
          path: '/operation-ship'
        },
        {
          name: 'Thiết lập chung',
          icon: 'nc-icon nc-settings-gear-65',
          children: [
            {
              name: 'Menu',
              path: '/menus'
            },
            {
              name: 'Cấu hình chung',
              path: '/settings'
            },
            {
              name: 'Caching',
              path: '/caching'
            }
          ]
        },
        {
          name: 'Quản lý hoạt động',
          icon: 'nc-icon nc-settings-gear-65',
          children: [
            {
              name: 'Workouts',
              path: '/activities/workout'
            },
            {
              name: 'Walk',
              path: '/activities/walk'
            },
            {
              name: 'Running',
              path: '/activities/run'
            }
          ]
        },
        {
          name: 'Quản trị viên',
          icon: 'nc-icon nc-badge',
          path: '/admins'
        }
      ]
    } else if (state.role == 2) {
      state.sidebarLinks = [
        {
          name: 'Quản lý cuộc đua',
          icon: 'nc-icon nc-user-run',
          children: [
            {
              name: 'Tất cả cuộc đua',
              path: '/races'
            },
            {
              name: 'Loại cuộc đua',
              path: '/category-race'
            },
            {
              name: 'Chủ đề cuộc đua',
              path: '/themes'
            },
            {
              name: 'Kiểu cuộc đua',
              path: '/race-type'
            },
            {
              name: 'Tiện ích',
              path: '/addons'
            },
            {
              name: 'Cự ly',
              path: '/catalogs'
            },
            {
              name: 'Địa điểm Offline',
              path: '/locations'
            }
          ]
        },
        {
          name: 'Quản lý quy định',
          icon: 'nc-icon nc-paper',
          path: '/rules'
        },
        {
          name: 'Quản lý quỹ từ thiện',
          icon: 'nc-icon nc-alert-circle-i',
          path: '/charities'
        }
      ]
    } else if (state.role == 3) {
      state.sidebarLinks = [
        {
          name: 'Quản lý đơn hàng',
          icon: 'nc-icon nc-single-copy-04',
          children: [{
            name: 'Đăng ký race',
            path: '/orders'
          },
          {
            name: 'Đóng góp quỹ từ thiện',
            path: '/donates'
          }]
        },
        {
          name: 'Quản lý người dùng',
          icon: 'nc-icon nc-single-02',
          children: [{
              name: 'Tất cả người dùng',
              path: '/users'
            },
            {
              name: 'Bảng',
              path: '/groups'
            },
            {
              name: 'Câu lạc bộ',
              path: '/clubs'
            },
            {
              name: 'Công ty',
              path: '/companies'
            },
            {
              name: 'Trường học',
              path: '/schools'
            }
          ]
        },
        {
          name: 'Quản lý Coupon',
          icon: 'nc-icon nc-tag-content',
          children: [
            {
              name: 'Tạo mới',
              path: '/coupons/create'
            },
            {
              name: 'Danh sách',
              path: '/coupons/all'
            }
          ]
        },
      ]
    } else if (state.role == 4) {
      state.sidebarLinks = [
        {
          name: 'Quản lý đơn hàng',
          icon: 'nc-icon nc-single-copy-04',
          children: [{
            name: 'Đăng ký race',
            path: '/orders'
          },
          {
            name: 'Mua sản phẩm',
            path: '/order-products'
          },
          {
            name: 'Đóng góp quỹ từ thiện',
            path: '/donates'
          }]
        },
        {
          name: 'Quản lý người dùng',
          icon: 'nc-icon nc-single-02',
          children: [{
              name: 'Tất cả người dùng',
              path: '/users'
            },
            {
              name: 'Bảng',
              path: '/groups'
            },
            {
              name: 'Câu lạc bộ',
              path: '/clubs'
            },
            {
              name: 'Công ty',
              path: '/companies'
            },
            {
              name: 'Trường học',
              path: '/schools'
            }
          ]
        }
        // {
        //   name: 'Quản lý mã vận đơn',
        //   icon: 'nc-icon nc-tag-content',
        //   path: '/bills'
        // }
      ]
    }
  },
  [types.AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [types.AUTH_SUCCESS]: (state, data) => {
    state.status = 'success';
    util.setCookie('token', data.token, 1);
    util.setCookie('user', JSON.stringify(data.user), 30);
    util.setCookie('role', data.user.role_id, 30);
    state.token = data.token;
    state.role = data.user.role_id;
    localStorage.setItem("current_user", JSON.stringify(data.user));
  },
  [types.AUTH_ERROR]: (state) => {
    state.status = 'error'
  },
  [types.LOGOUT]: (state) => {
    util.eraseCookie('token');
    util.eraseCookie('user');
    util.eraseCookie('role');
    localStorage.removeItem('current_user');
    state.token = '';
  },

  [types.FETCH_MAIL_TYPES](state, data) {
    state.mailTypes = data;
  },

  [types.FETCH_ORDERS](state, orders) {
    state.orders = orders;
  },

  [types.FETCH_DONATES](state, data) {
    state.donates = data;
  },

  [types.FETCH_ORDER_PRODUCTS](state, data) {
    state.orderProducts = data;
  },

  [types.FETCH_ORDER_VIPS](state, data) {
    state.orderVips = data;
  },

  [types.FETCH_ORDER_VIP_DETAIL](state, data) {
    state.orderOrderVipDetail = data;
  },

  [types.FETCH_WORKOUTS](state, data) {
    state.workouts = data;
  },

  [types.FETCH_WALKING](state, data) {
    state.walking = data;
  },

  [types.FETCH_RUNNING](state, data) {
    state.running = data;
  },

  [types.FETCH_BILLS](state, bills) {
    state.bill_races = bills.data.races;
    state.bill_transporters = bills.data.transporters;
  },

  [types.FETCH_ALL_BILLS](state, bills) {
    state.bills = bills;
  },

  [types.FETCH_ALL_ACTIVITIES_CHEAT](state, data) {
    state.activities_cheat = data.activities;
  },

  [types.FETCH_SHIPPING_STATUS](state, data) {
    state.shipping_status = data;
  },

  [types.FETCH_SHIPPING_CONFIG](state, data) {
    state.shippingConfig = data;
  },

  [types.RACE_ID](state, race_id) {
    state.race_id = race_id;
  },

  [types.FETCH_ACTIVITIES](state, activities) {
    state.activities = activities;
  },

  [types.FETCH_ORDER_DETAIL](state, data) {
    state.orderDetail = data;
  },

  [types.FETCH_ORDER_ENTERPRISE_DETAIL](state, data) {
    state.orderEnterpriseDetail = data;
  },

  [types.FETCH_ACTIVITY_DETAIL](state, data) {
    state.activityDetail = data;
  },

  [types.FETCH_PROVINCES](state, data) {
    state.provinces = data;
  },

  [types.FETCH_DISTRICTS](state, data) {
    state.districts = data;
  },

  [types.FETCH_WARDS](state, data) {
    state.wards = data;
  },

  // USERS
  [types.FETCH_USERS](state, users) {
    state.users = users;
  },

  [types.FETCH_USER_DETAIL](state, user) {
    state.userDetail = user;
  },

  [types.FETCH_STAFF_DETAIL](state, staff) {
    state.staffDetail = staff;
  },

  [types.FETCH_ALL_SETTING](state, setting) {
    state.allSettings = setting;
  },

  [types.FETCH_ALL_CACHING](state, data) {
    state.allCaching = data;
  },

  [types.FETCH_CHARITIES](state, charities) {
    state.charities = charities;
  },

  [types.FETCH_PACKAGES](state, data) {
    state.packages = data;
  },

  [types.FETCH_ORDER_ENTERPRISES](state, data) {
    state.enterpriseOrders = data;
  },

  [types.FETCH_PACKAGE_DETAIL](state, data) {
    state.packageDetail = data;
  },

  [types.FETCH_CHARITY_DETAIL](state, charity) {
    state.charityDetail = charity;
  },

  // RACES
  [types.FETCH_RACES](state, races) {
    state.races = races;
  },

  [types.FETCH_ALL_RACES](state, races) {
    state.allRaces = races;
  },

  [types.FETCH_ALL_RACES_SHORT](state, races) {
    state.shortRaces = races;
  },

  [types.FETCH_ALL_ENTERPRISE_RACES](state, data) {
    state.enterpriseRaces = data;
  },

  [types.FETCH_RACE_DETAIL](state, race) {
    state.raceDetail = race;
  },

  [types.FETCH_RACE_TYPES](state, data) {
    state.raceTypes = data;
  },

  [types.FETCH_RACE_TYPE_DETAIL] (state, type) {
    state.raceTypeDetail = type;
  },

  [types.REMOVE_RACE_TYPE](state, resp) {
    state.raceTypes = state.raceTypes.filter((row) => {
      return row.id != resp.id;
    });
  },

  [types.FETCH_RACE_CATEGORIES](state, data) {
    state.raceCategories = data;
  },

  [types.FETCH_RACE_CATEGORY_DETAIL] (state, type) {
    state.raceCateDetail = type;
  },

  [types.REMOVE_RACE_CATEGORY](state, resp) {
    state.raceCategories = state.raceCategories.filter((row) => {
      return row.id != resp.id;
    });
  },

  [types.FETCH_RACE_CATALOGS](state, data) {
    state.raceCatalogs = data;
  },

  [types.FETCH_RACE_ADDONS](state, data) {
    state.raceAddons = data;
  },

  [types.FETCH_ADDONS](state, data) {
    state.addons = data;
  },

  [types.FETCH_CUSTOMER](state, customers) {
    state.customers = customers;
  },

  [types.FETCH_SETTING](state, settings) {
    state.settings = settings;
  },

  [types.FETCH_METAFIELD_OBJECT](state, data) {
    state.metafield_object = data;
  },

  [types.FETCH_MENUS](state, menus) {
    state.menus = menus;
  },

  [types.FETCH_MENU_DETAIL](state, menu) {
    state.menuDetail = menu;
  },

  [types.REMOVE_MENU](state, id) {
    state.menus = state.menus.filter((row) => {
      return row.id != id;
    });
  },

  [types.FETCH_HISTORY](state, historys) {
    state.historys = historys;
  },

  [types.FETCH_SHIPPING](state, shippings) {
    state.shippings = shippings;
  },

  [types.FETCH_ROLE](state, roles) {
    state.roles = roles;
  },

  [types.FETCH_DELETED](state, deleted) {
    state.deleted = deleted;
  },

  [types.MESSAGE](state, payload) {
  },

  [types.SET_CURRENT_ACTIONS](state, payload) {
    state.currentActions = payload;
  },

  [types.SET_CURRENT_FILTER_ORDERS](state, payload) {
    state.currentFilterOrders = payload;
  },

  [types.SET_CURRENT_FILTER_USERS](state, payload) {
    state.currentFilterUsers = payload;
  },

  [types.SET_FILTER_TAGS](state, payload) {
    state.filterTags = payload;
  },

  [types.SET_PAGE_TITLE] (state, title) {
    state.pageTitle = title;
  },

  [types.FETCH_ADDON_DETAIL] (state, addon) {
    state.addonDetail = addon;
  },


  [types.FETCH_CATALOG_DETAIL] (state, catalog) {
    state.catalogDetail = catalog;
  },

  [types.STORE_ADDON](state, resp) {
    let {name, order_status, total, created_at} = resp.form;
    let {id} = resp.data;
    state.addons.push({
      id, name, order_status, total, created_at
    });
  },

  [types.REMOVE_ADDON](state, resp) {
    state.addons = state.addons.filter((row) => {
      return row.id != resp.id;
    });
  },

  [types.REMOVE_CATALOG](state, resp) {
    state.raceCatalogs = state.raceCatalogs.filter((row) => {
      return row.id != resp.id;
    });
  },

  [types.FETCH_RULES] (state, data) {
    state.rules = data;
  },

  [types.FETCH_VIPS] (state, data) {
    state.vips = data;
  },

  [types.FETCH_RACE_RULES] (state, data) {
    state.raceRules = data;
  },

  [types.FETCH_RULE_DETAIL] (state, rule) {
    state.ruleDetail = rule;
  },

  [types.FETCH_VIP_DETAIL] (state, data) {
    state.vipDetail = data;
  },

  [types.STORE_RULE](state, resp) {
    let {name} = resp.form;
    let {id} = resp.data;
    state.rules.push({
      id, name
    });
  },

  [types.REMOVE_RULE](state, resp) {
    state.rules = state.rules.filter((row) => {
      return row.id != resp.id;
    });
  },

  [types.FETCH_THEMES] (state, data) {
    state.themes = data;
  },

  [types.FETCH_THEME_DETAIL] (state, theme) {
    state.themeDetail = theme;
  },

  [types.STORE_THEME](state, resp) {
    let {name} = resp.form;
    let {id} = resp.data;
    state.themes.push({
      id, name
    });
  },

  [types.REMOVE_THEME](state, resp) {
    state.themes = state.themes.filter((row) => {
      return row.id != resp.id;
    });
  },

  [types.FETCH_NOTIFICATIONS] (state, notifications) {
    state.notifications = notifications;
  },

  [types.STORE_NOTIFICATION](state, resp) {
    let {title, vi_content, en_content, type_id} = resp.form;
    let {id} = resp.data;
    state.notifications.push({
      id, title, type_id, vi_content
    });
  },

  [types.FETCH_COUPONS] (state, coupons) {
    state.coupons = coupons;
  },

  [types.FETCH_COUPON_DETAIL] (state, coupon) {
    state.couponDetail = coupon;
  },

  [types.STORE_COUPON](state, resp) {
    let {id, title, end_at, price, rest, note} = resp.data;
    state.coupons.push({
      id, title, end_at, price, rest, note
    });
  },

  [types.FETCH_CLUBS] (state, clubs) {
    state.clubs = clubs;
  },

  [types.FETCH_LOCATIONS](state, locations) {
    state.locations = locations;
  },

  [types.FETCH_GROUP_CLUBS](state, data) {
    state.groupClubs = data;
  },

  [types.FETCH_CLUB_DETAIL] (state, club) {
    state.clubDetail = club;
  },

  [types.FETCH_LOCATION_DETAIL](state, location) {
    state.locationDetail = location;
  },

  [types.FETCH_COMPANIES] (state, companies) {
    state.companies = companies;
  },

  [types.FETCH_GROUPS] (state, data) {
    state.groups = data;
  },

  [types.FETCH_COMPANY_DETAIL] (state, company) {
    state.companyDetail = company;
  },

  [types.FETCH_GROUP_DETAIL] (state, data) {
    state.groupDetail = data;
  },

  [types.FETCH_SCHOOLS](state, schools) {
    state.schools = schools;
  },

  [types.FETCH_SCHOOL_DETAIL](state, school) {
    state.schoolDetail = school;
  },

  [types.FETCH_MAILS] (state, mails) {
    state.mails = mails;
  },

  [types.FETCH_MAIL_FORMS] (state, data) {
    state.mailForms = data;
  },

  [types.FETCH_SEND_MAIL_HISTORY] (state, data) {
    state.sendMailHistory = data;
  },

  [types.FETCH_MAIL_DETAIL] (state, mail) {
    state.mailDetail = mail;
  },

  [types.FETCH_MAIL_FORM_DETAIL] (state, data) {
    state.mailFormDetail = data;
  },

  [types.FETCH_ADMINS] (state, admins) {
    state.admins = admins;
  },

  [types.STORE_ADMIN](state, resp) {
    state.admins.push(resp.data);
  },

  [types.FETCH_ADMIN_DETAIL] (state, admin) {
    state.adminDetail = admin;
  },

  [types.FETCH_DASHBOARD] (state, data) {
    state.dashboard = data;
    state.statsCards = [
      {
        type: 'warning',
        icon: 'nc-icon nc-single-02',
        title: 'Đăng ký trong ngày',
        value: data.user_count_daily.now,
        footerText: `Hôm qua: ${data.user_count_daily.past}`,
        footerIcon: '',
        footerValue: data.user_count_daily.change
      },
      {
        type: 'success',
        icon: 'nc-icon nc-single-02',
        title: 'Đăng ký trong tuần',
        value: data.user_count_weekly.now,
        footerText: `Tuần trước: ${data.user_count_weekly.past}`,
        footerIcon: '',
        footerValue: data.user_count_weekly.change
      },
      {
        type: 'danger',
        icon: 'nc-icon nc-single-02',
        title: 'Đăng ký trong tháng',
        value: data.user_count_monthly.now,
        footerText: `Tháng trước: ${data.user_count_monthly.past}`,
        footerIcon: '',
        footerValue: data.user_count_monthly.change
      },
      {
        type: 'warning',
        icon: 'nc-icon nc-user-run',
        title: data.raceTypes[0].name,
        value: data.raceTypes[0].user_count   + ' Người',
      },
      {
        type: 'success',
        icon: 'nc-icon nc-user-run',
        title: data.raceTypes[1].name,
        value: data.raceTypes[1].user_count + ' Người',
      },
      {
        type: 'danger',
        icon: 'nc-icon nc-user-run',
        title: data.raceTypes[2].name,
        value: data.raceTypes[2].user_count  + ' Người',
      },
      {
        type: 'warning',
        icon: 'nc-icon nc-trophy',
        title: 'Doanh thu trong ngày',
        value: data.order_sum_daily.now,
        footerText: `Hôm qua: ${data.order_sum_daily.past}đ`,
        footerIcon: '',
        footerValue: data.order_sum_daily.change
      },
      {
        type: 'success',
        icon: 'nc-icon nc-trophy',
        title: 'Doanh thu trong tuần',
        value: data.order_sum_weekly.now,
        footerText: `Tuần trước: ${data.order_sum_weekly.past}đ`,
        footerIcon: '',
        footerValue: data.order_sum_weekly.change
      },
      {
        type: 'danger',
        icon: 'nc-icon nc-trophy',
        title: 'Doanh thu trong tháng',
        value: data.order_sum_monthly.now,
        footerText: `Tháng trước: ${data.order_sum_monthly.past}đ`,
        footerIcon: '',
        footerValue: data.order_sum_monthly.change
      },
    ];
    state.statisticUserCheckout = {
      labels: data.statisticUserCheckout.arr_user_days,
      datasets: [
        {
          label: 'Số người đăng ký',
          backgroundColor: '#6bd098',
          data: data.statisticUserCheckout.arr_user_count
        }
      ]
    };
    state.statisticRevenue = {
      labels: data.statisticRevenue.arr_revenue_days,
      datasets: [
        {
          label: 'Doanh thu',
          backgroundColor: '#ef8157',
          data: data.statisticRevenue.arr_revenue_total
        }
      ]
    };
  }

}
