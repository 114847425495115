var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      class: [
        { "card-plain": _vm.plain },
        { "card-raised": _vm.raised },
        { [`card-${_vm.type}`]: _vm.type },
      ],
      attrs: { "data-background-color": _vm.color },
    },
    [
      _vm.$slots.category || _vm.category
        ? _c("h6", { staticClass: "card-category" }, [
            _vm._v(_vm._s(_vm.category)),
          ])
        : _vm._e(),
      _vm.$slots.image
        ? _c("div", { staticClass: "card-image" }, [_vm._t("image")], 2)
        : _vm._e(),
      _vm.$slots.avatar
        ? _c("div", { staticClass: "card-avatar" }, [_vm._t("avatar")], 2)
        : _vm._e(),
      _vm.$slots.info
        ? _c("div", { staticClass: "info" }, [_vm._t("info")], 2)
        : _vm._e(),
      _vm.$slots.header || _vm.title
        ? _c(
            "div",
            { staticClass: "card-header", class: _vm.headerClasses },
            [
              _vm._t("header", function () {
                return [
                  _vm.title
                    ? _c("h1", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ])
                    : _vm._e(),
                  _vm.subTitle
                    ? _c("h3", { staticClass: "card-category" }, [
                        _vm._v(_vm._s(_vm.subTitle)),
                      ])
                    : _vm._e(),
                  _vm.description
                    ? _c("p", { staticClass: "card-description" }, [
                        _vm._v(_vm._s(_vm.description)),
                      ])
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.$slots.default
        ? _c(
            "div",
            { staticClass: "card-body", class: _vm.cardBodyClasses },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._t("raw-content"),
      _vm.$slots.footer && _vm.footerLine ? _c("hr") : _vm._e(),
      _vm.$slots.footer
        ? _c("div", { staticClass: "card-footer" }, [_vm._t("footer")], 2)
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }