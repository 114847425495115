var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar",
      attrs: {
        "data-color": _vm.backgroundColor,
        "data-active-color": _vm.activeColor,
      },
    },
    [
      _c(
        "div",
        { ref: "sidebarScrollArea", staticClass: "sidebar-wrapper" },
        [
          _vm._t("default"),
          _c(
            "ul",
            { staticClass: "nav" },
            [
              _vm._t("links", function () {
                return _vm._l(_vm.sidebarLinks, function (link, index) {
                  return _c(
                    "sidebar-item",
                    { key: link.name + index, attrs: { link: link } },
                    _vm._l(link.children, function (subLink, index) {
                      return _c("sidebar-item", {
                        key: subLink.name + index,
                        attrs: { link: subLink },
                      })
                    }),
                    1
                  )
                })
              }),
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }