var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "card card-plain" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h4", { staticClass: "card-title pl-3" }, [
            _vm._v("SM Grid\n        "),
            _c("small", [_vm._v("Collapsed at 576px")]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-sm-4")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-sm-4")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-sm-4")]),
                ]),
              ]),
            ]),
          ]),
          _c("h4", { staticClass: "card-title pl-3" }, [
            _vm._v("MD Grid\n        "),
            _c("small", [_vm._v("Collapsed at 768px")]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-md-4")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-md-4")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-md-4")]),
                ]),
              ]),
            ]),
          ]),
          _c("h4", { staticClass: "card-title pl-3" }, [
            _vm._v("LG Grid\n        "),
            _c("small", [_vm._v("Collapsed at 992px")]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-lg-4")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-lg-4")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-lg-4")]),
                ]),
              ]),
            ]),
          ]),
          _c("h4", { staticClass: "card-title pl-3" }, [
            _vm._v("XL Grid\n        "),
            _c("small", [_vm._v("Collapsed at 1200px")]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xl-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-xl-4")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-xl-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-xl-4")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-xl-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-xl-4")]),
                ]),
              ]),
            ]),
          ]),
          _c("h4", { staticClass: "card-title pl-3" }, [
            _vm._v("Mixed Grid\n        "),
            _c("small", [
              _vm._v("Showing different sizes on different screens"),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-sm-6 col-lg-3")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-sm-6 col-lg-3")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-sm-6 col-lg-3")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-sm-6 col-lg-3")]),
                ]),
              ]),
            ]),
          ]),
          _c("h4", { staticClass: "card-title pl-3" }, [
            _vm._v("Offset Grid\n        "),
            _c("small", [_vm._v("Adding some space when needed")]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-md-3")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-md-3 ml-auto" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-md-3 ml-auto")]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 ml-auto mr-auto" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-md-4 ml-auto mr-auto")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-md-4 ml-auto mr-auto" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-md-4 ml-auto mr-auto")]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6 ml-auto mr-auto" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("code", [_vm._v("col-md-6 ml-auto mr-auto")]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("h3", { staticClass: "title mt-4 text-center" }, [
        _vm._v("Paragraphs"),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("h3", [_vm._v("Some Title Here")]),
              _c("p", [
                _vm._v(
                  'One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. "What\'s happened to me?" he thought.'
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("h3", [_vm._v("Another Title Here")]),
              _c("p", [
                _vm._v(
                  'One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. "What\'s happened to me?" he thought.'
                ),
              ]),
            ]),
          ]),
          _c("br"),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c("h3", [_vm._v("Some Title Here")]),
              _c("p", [
                _vm._v(
                  "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("h3", [_vm._v("Another Title Here")]),
              _c("p", [
                _vm._v(
                  "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("h3", [_vm._v("Another Title Here")]),
              _c("p", [
                _vm._v(
                  "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                ),
              ]),
            ]),
          ]),
          _c("br"),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c("h3", [_vm._v("Some Title Here")]),
              _c("p", [
                _vm._v(
                  "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-8" }, [
              _c("h3", [_vm._v("Another Title Here")]),
              _c("p", [
                _vm._v(
                  "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }