var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v("Welcome to "),
      _c("b", [_vm._v("Paper Dashboard")]),
      _vm._v(" - a beautiful freebie for every web developer."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }