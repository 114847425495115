var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "nav-open": _vm.$sidebar.showSidebar } },
    [
      _c("notifications", {
        attrs: {
          "transition-name": "notification-list",
          "transition-mode": "out-in",
        },
      }),
      _c("router-view", { attrs: { name: "header" } }),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("router-view")],
        1
      ),
      _c("router-view", { attrs: { name: "footer" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }