<template>
  <div class="login-page">
    <!-- <app-navbar></app-navbar> -->
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <card type="login">
                <h3 slot="header" class="header text-center">Đăng nhập</h3>

                <fg-input
                  v-model="form.email"
                  addon-left-icon="nc-icon nc-email-85"
                  placeholder="Email..."
                  @keyup.enter="signIn"
                  :class="errors.has('email')?'border-danger':''"
                  v-validate="'required|email'"
                  data-vv-name="email"
                  data-vv-as="Email"
                ></fg-input>
                <span class="text-danger" v-if="errors.has('email')">{{ errors.first('email') }}</span>

                <fg-input
                  v-model="form.password"
                  addon-left-icon="nc-icon nc-key-25"
                  placeholder="Mật khẩu"
                  type="password"
                  @keyup.enter="signIn"
                  :class="errors.has('password')?'border-danger':''"
                  v-validate="'required'"
                  data-vv-name="password"
                  data-vv-as="Mật khẩu"
                ></fg-input>
                <span class="text-danger" v-if="errors.has('password')">{{ errors.first('password') }}</span>

                <br>

                <p-button slot="footer" type="primary" round block class="mb-3" @click="signIn">Đăng nhập</p-button>
              </card>
            </div>
          </div>
        </div>
        <div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Card, Checkbox, Button} from 'src/components/UIComponents';
  import { Message } from 'element-ui';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import api from 'src/store/api';

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      Message
    },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      signIn (){
        let self = this;
        this.$validator.validateAll().then(function (result) {
          if (result) {
            let data = {};
            data.email = self.form.email;
            data.password = self.form.password;
            self.$store.dispatch('authLogin', data).then(res => {
              if (!res.code) {
                Message({
                  message: 'Đăng nhập thành công',
                  type: 'success'
                });
                localStorage.setItem("login_time", new Date().getTime());
                const redirect_url = localStorage.getItem("redirect_url");
                if (redirect_url) {
                  localStorage.removeItem('redirect_url');
                  location.href = redirect_url;
                  return;
                }
                const role = self.$store.state.role;
                if (role == 2) {
                  self.$router.push('/races');
                } else if (role == 3) {
                  self.$router.push('/orders');
                } else if (role == 4) {
                  self.$router.push('/bills');
                } else {
                  self.$router.push('/admin/overview');
                }
              } else {
                Message({
                  message: res.message,
                  type: 'error'
                });
              }
            }, error => {
              console.error(error);
            });
          }
        })
      }
    },
    data() {
      return {
        form: {
          email: '',
          password: ''
        }
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
