var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "side-bar",
        {
          attrs: {
            type: "sidebar",
            "sidebar-links": _vm.$store.state.sidebarLinks,
            logo: this.$store.state.sidebarLogo,
          },
        },
        [_c("user-menu")],
        1
      ),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("top-navbar"),
          _c("dashboard-content", {
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSidebar.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }