var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "btn",
      class: _vm.classes,
      attrs: { type: _vm.nativeType },
      on: { click: _vm.handleClick },
    },
    [
      _vm.$slots.label
        ? _c("span", { staticClass: "btn-label" }, [_vm._t("label")], 2)
        : _vm._e(),
      _vm._t("default"),
      _vm.$slots.labelRight
        ? _c(
            "span",
            { staticClass: "btn-label btn-label-right" },
            [_vm._t("labelRight")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }