var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-4 col-md-5" },
      [_c("user-card"), _c("members-card")],
      1
    ),
    _c(
      "div",
      { staticClass: "col-lg-8 col-md-7" },
      [_c("edit-profile-form")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }