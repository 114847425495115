import Vue from 'vue'
import Vuex from 'vuex'
import {actions} from './actions'
import {mutations} from './mutations'
import {getters} from './getters'
import 'vuex-toast/dist/vuex-toast.css'
import { createModule } from 'vuex-toast'
import util from '../helpers/util'

Vue.use(Vuex)

const state = {
  pageTitle: 'iRace Dashboard',
  sidebarLogo: '/static/img/vue-logo.png',
  token: util.getCookie('token') || '',
  role: util.getCookie('role') || '',
  fullname: '',
  user_id: '',
  status: '',
  bills: [],
  bill_races: [],
  bill_transporters: [],
  activities_cheat: [],
  mailTypes: [],
  sendMailHistory: [],
  orders: [],
  donates: [],
  shipping_status: [],
  shippingConfig: [],
  orderProducts: [],
  orderVips: [],
  orderOrderVipDetail: [],
  activities: [],
  workouts: [],
  walking: [],
  running: [],
  cities: [],
  districts: [],
  wards: [],
  provinces: [],
  orderDetail: [],
  ordersDraft: [],
  customers: [],
  customerDetail: [],
  settings: [],
  menus: [],
  menuDetail: [],
  users: [],
  userDetail: [],
  staffDetail: [],
  allSettings: [],
  allCaching: [],
  charities: [],
  packages: [],
  metafield_object: [],
  packageDetail: [],
  enterpriseOrders: [],
  enterpriseRaces: [],
  orderEnterpriseDetail: [],
  charityDetail: [],
  historys: [],
  shippings: [],
  roles: [],
  deleted: [],
  currentActions: [],
  currentFilterOrders: [],
  filterTags: [],
  addons: [],
  addonDetail: [],
  rules: [],
  vips: [],
  vipDetail: [],
  raceRules: [],
  ruleDetail: [],
  themes: [],
  groups: [],
  themeDetail: [],
  groupDetail: [],
  catalogDetail: [],
  notifications: [],
  typeRaces: [],
  coupons: [],
  couponDetail: [],
  clubs: [],
  clubDetail: [],
  locations: [],
  locationDetail: [],
  groupClubs: [],
  companies: [],
  companyDetail: [],
  schools: [],
  schoolDetail: [],
  mails: [],
  mailForms: [],
  mailDetail: [],
  mailFormDetail: [],
  raceTypes: [],
  raceTypeDetail: [],
  raceCategories: [],
  raceCateDetail: [],
  raceCatalogs: [],
  raceAddons: [],
  raceDetail: [],
  races: [],
  activityDetail: [],
  allRaces: [],
  shortRaces: [],
  admins: [],
  dashboard: [],
  dashboardRace: [],
  race_id: '',
  statsCards: [
    {
      type: 'warning',
      icon: 'nc-icon nc-single-02',
      title: 'Đăng ký trong ngày',
      value: '0',
      footerText: 'Hôm qua: 0',
      footerIcon: '',
      footerValue: '0%'
    },
    {
      type: 'success',
      icon: 'nc-icon nc-single-02',
      title: 'Đăng ký trong tuần',
      value: '0',
      footerText: 'Tuần trước: 0',
      footerIcon: '',
      footerValue: '0%'
    },
    {
      type: 'danger',
      icon: 'nc-icon nc-single-02',
      title: 'Đăng ký trong tháng',
      value: '0',
      footerText: 'Tháng trước: 0',
      footerIcon: '',
      footerValue: '0%'
    },
    {
      type: 'warning',
      icon: 'nc-icon nc-user-run',
      title: 'RUN',
      value: '0 Người',
    },
    {
      type: 'success',
      icon: 'nc-icon nc-user-run',
      title: 'BIKE',
      value: '0 Người',
    },
    {
      type: 'danger',
      icon: 'nc-icon nc-user-run',
      title: 'SWIM',
      value: '0 Người',
    },
    {
      type: 'warning',
      icon: 'nc-icon nc-trophy',
      title: 'Doanh thu trong ngày',
      value: '0',
      footerText: 'Hôm qua: 0',
      footerIcon: '',
      footerValue: '0%'
    },
    {
      type: 'success',
      icon: 'nc-icon nc-trophy',
      title: 'Doanh thu trong tuần',
      value: '0',
      footerText: 'Tuần trước: 0',
      footerIcon: '',
      footerValue: '0%'
    },
    {
      type: 'danger',
      icon: 'nc-icon nc-trophy',
      title: 'Doanh thu trong tháng',
      value: '0',
      footerText: 'Tháng trước: 0',
      footerIcon: '',
      footerValue: '0%'
    },
  ],
  statisticRevenue: {},
  statisticUserCheckout: {},
  sidebarLinks: []
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    toast: createModule({
      dismissInterval: 8000
    })
  }
})
