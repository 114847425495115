var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-6 col-md-12" },
        [
          _c("div"),
          _c(
            "card",
            [
              _c(
                "h5",
                {
                  staticClass: "card-title",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_vm._v("Collapsible Accordion")]
              ),
              _c(
                "collapse",
                [
                  _c(
                    "collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "1" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.\n            "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "2" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.\n            "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "3" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.\n            "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-lg-6 col-md-12" },
        [
          _c(
            "card",
            { attrs: { type: "plain" } },
            [
              _c(
                "h5",
                {
                  staticClass: "card-title",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_vm._v("Collapsible Accordion on Plain Card")]
              ),
              _c(
                "collapse",
                [
                  _c(
                    "collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "1" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.\n            "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "2" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.\n            "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "3" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.\n            "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-6 col-md-12" },
        [
          _c(
            "card",
            [
              _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Horizontal Tabs"),
              ]),
              _c(
                "tabs",
                {
                  attrs: {
                    value: "Description",
                    "tab-content-classes": "text-center",
                  },
                },
                [
                  _c("tab-pane", { key: "home", attrs: { title: "Home" } }, [
                    _c("p", [
                      _vm._v(
                        "Larger, yet dramatically thinner.\n              More powerful, but remarkably power efficient. With a smooth metal surface that seamlessly meets the new Retina HD display."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n              It’s one continuous form where hardware and software function in perfect unison, creating a new generation of phone that’s better by any measure."
                      ),
                    ]),
                  ]),
                  _c(
                    "tab-pane",
                    { key: "profile", attrs: { title: "Profile" } },
                    [_c("p", [_vm._v("Here is your profile")])]
                  ),
                  _c(
                    "tab-pane",
                    { key: "messages", attrs: { title: "Messages" } },
                    [_c("p", [_vm._v("Here is your messages")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-lg-6 col-md-12" },
        [
          _c(
            "card",
            [
              _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Vertical Tabs"),
              ]),
              _c(
                "tabs",
                { attrs: { vertical: "" } },
                [
                  _c("tab-pane", { key: "home", attrs: { title: "Home" } }, [
                    _vm._v(
                      "\n            Larger, yet dramatically thinner. More powerful, but remarkably power efficient. With a smooth metal surface that seamlessly meets the new Retina HD display.\n          "
                    ),
                  ]),
                  _c(
                    "tab-pane",
                    { key: "profile", attrs: { title: "Profile" } },
                    [_vm._v("Here is your profile.")]
                  ),
                  _c(
                    "tab-pane",
                    { key: "messages", attrs: { title: "Messages" } },
                    [_vm._v("Here are your messages.")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-8 ml-auto mr-auto" },
        [
          _c(
            "card",
            { staticClass: "card-subcategories", attrs: { type: "plain" } },
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "card-title text-center" }, [
                  _vm._v("Page Subcategories"),
                ]),
                _c("br"),
              ]),
              _c(
                "tabs",
                {
                  attrs: {
                    pills: "",
                    type: "primary",
                    icons: "",
                    centered: "",
                    "tab-content-classes": "tab-space",
                  },
                },
                [
                  _c("tab-pane", { key: "home", attrs: { id: "home" } }, [
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _c("i", { staticClass: "nc-icon nc-umbrella-13" }),
                      _vm._v(" Home\n            "),
                    ]),
                    _vm._v(
                      "\n\n            Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits.\n            "
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " Dramatically visualize customer directed convergence without revolutionary ROI.\n\n          "
                    ),
                  ]),
                  _c(
                    "tab-pane",
                    { key: "messages", attrs: { id: "messages" } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _c("i", { staticClass: "nc-icon nc-shop" }),
                        _vm._v(" Messages\n            "),
                      ]),
                      _vm._v(
                        "\n\n            Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas.\n            "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "Dramatically maintain clicks-and-mortar solutions without functional solutions.\n\n          "
                      ),
                    ]
                  ),
                  _c(
                    "tab-pane",
                    { key: "settings", attrs: { id: "settings" } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _c("i", { staticClass: "nc-icon nc-settings" }),
                        _vm._v(" Settings\n            "),
                      ]),
                      _vm._v(
                        "\n\n            Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas.\n            "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "Dynamically innovate resource-leveling customer service for state of the art customer service.\n\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }