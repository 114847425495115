var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-area", class: `info-${_vm.direction}` },
    [
      _vm.$slots.icon || _vm.icon
        ? _c(
            "div",
            { staticClass: "icon", class: `icon-${_vm.type}` },
            [
              _vm._t("icon", function () {
                return [_c("i", { class: _vm.icon })]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.$slots.default || _vm.title
        ? _c(
            "div",
            { staticClass: "description" },
            [
              _vm._t("default", function () {
                return [
                  _c("h5", { staticClass: "info-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c("p", { staticClass: "description" }, [
                    _vm._v(_vm._s(_vm.description)),
                  ]),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }