import { render, staticRenderFns } from "./StatsCard.vue?vue&type=template&id=97c60ba4&scoped=true&"
import script from "./StatsCard.vue?vue&type=script&lang=js&"
export * from "./StatsCard.vue?vue&type=script&lang=js&"
import style0 from "./StatsCard.vue?vue&type=style&index=0&id=97c60ba4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97c60ba4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/truyen/workspace/dlvn/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('97c60ba4')) {
      api.createRecord('97c60ba4', component.options)
    } else {
      api.reload('97c60ba4', component.options)
    }
    module.hot.accept("./StatsCard.vue?vue&type=template&id=97c60ba4&scoped=true&", function () {
      api.rerender('97c60ba4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/UIComponents/Cards/StatsCard.vue"
export default component.exports