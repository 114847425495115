var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card card-timeline card-plain" }, [
    _c("div", { staticClass: "card-body" }, [
      _c(
        "ul",
        {
          staticClass: "timeline",
          class: { "timeline-simple": _vm.type === "simple" },
        },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }