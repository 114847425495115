import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import VueNotify from 'vue-notifyjs'
import VeeValidate from 'vee-validate'
import VueValidationVi from 'vee-validate/dist/locale/vi';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'

// store/vuex setup
import store from './store/store'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar';

// router setup
import routes from './routes/routes'

// library imports

import './assets/sass/paper-dashboard.scss'
import './assets/sass/element_variables.scss'
import './assets/sass/global.scss'

// import sidebarLinks from './sidebarLinks'
// plugin setup
Vue.use(VueRouter)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, {sidebarLinks: store.state.sidebarLinks})
Vue.use(VeeValidate, {
  locale: 'vi',
  dictionary: {
    vi: VueValidationVi
  }
})
locale.use(lang)

// cusombize
import lodash from 'lodash'
import util from 'src/helpers/util'
// Vue.prototype.$_ = lodash;

Object.defineProperty(Vue.prototype, '$_', {
  get () {
    return lodash
  }
})

Object.defineProperty(Vue.prototype, 'window', {
  get () {
    return window
  }
})

Object.defineProperty(Vue.prototype, '$util', {
  get () {
    return util
  }
})


// configure router
export const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
})

router.beforeEach((to, from, next) => {
  if (to['path'] === '/login') {
    if (!store.getters.isAuthenticated) {
      next();
      return;
    }
    next('/');
  } else {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    localStorage.setItem("redirect_url", location.href);
    next('/login');
  }
})

initProgress(router);

// google map
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAkK1Nj9HWtb4R0crJISga3j9hq2aBC8lQ',
    libraries: 'places'
  }
})

/* eslint-disable no-new */
window.vueApp = new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})
