var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "pagination", class: _vm.paginationClass },
    [
      _c(
        "li",
        {
          staticClass: "page-item prev-page",
          class: { disabled: _vm.value === 1 },
        },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              attrs: { "aria-label": "Previous" },
              on: { click: _vm.prevPage },
            },
            [_vm._m(0)]
          ),
        ]
      ),
      _vm._l(_vm.range(_vm.minPage, _vm.maxPage), function (item) {
        return _c(
          "li",
          { staticClass: "page-item", class: { active: _vm.value === item } },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                on: {
                  click: function ($event) {
                    return _vm.changePage(item)
                  },
                },
              },
              [_vm._v(_vm._s(item))]
            ),
          ]
        )
      }),
      _c(
        "li",
        {
          staticClass: "page-item next-page",
          class: { disabled: _vm.value === _vm.totalPages },
        },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              attrs: { "aria-label": "Next" },
              on: { click: _vm.nextPage },
            },
            [_vm._m(1)]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { attrs: { "aria-hidden": "true" } }, [
      _c("i", {
        staticClass: "fa fa-angle-double-left",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { attrs: { "aria-hidden": "true" } }, [
      _c("i", {
        staticClass: "fa fa-angle-double-right",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }