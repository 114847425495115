var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row buttons-with-margin" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("p-button", [_vm._v("Default")]),
                _c("p-button", { attrs: { type: "primary" } }, [
                  _vm._v("Primary"),
                ]),
                _c("p-button", { attrs: { type: "info" } }, [_vm._v("Info")]),
                _c("p-button", { attrs: { type: "success" } }, [
                  _vm._v("Success"),
                ]),
                _c("p-button", { attrs: { type: "warning" } }, [
                  _vm._v("Warning"),
                ]),
                _c("p-button", { attrs: { type: "danger" } }, [
                  _vm._v("Danger"),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("p-button", { attrs: { type: "info" } }, [
                  _c("i", {
                    staticClass: "nc-icon nc-settings-gear-65",
                    attrs: { slot: "label" },
                    slot: "label",
                  }),
                  _vm._v("\n              Info\n            "),
                ]),
                _c("p-button", { attrs: { type: "success" } }, [
                  _c("i", {
                    staticClass: "nc-icon nc-check-2",
                    attrs: { slot: "label" },
                    slot: "label",
                  }),
                  _vm._v("\n              Success\n            "),
                ]),
                _c("p-button", { attrs: { type: "warning" } }, [
                  _c("i", {
                    staticClass: "nc-icon nc-zoom-split",
                    attrs: { slot: "label" },
                    slot: "label",
                  }),
                  _vm._v("\n              Warning\n            "),
                ]),
                _c("p-button", { attrs: { type: "danger" } }, [
                  _c("i", {
                    staticClass: "nc-icon nc-simple-remove",
                    attrs: { slot: "label" },
                    slot: "label",
                  }),
                  _vm._v("\n              Danger\n            "),
                ]),
                _c("p-button", [
                  _c("i", {
                    staticClass: "nc-icon nc-minimal-left",
                    attrs: { slot: "label" },
                    slot: "label",
                  }),
                  _vm._v("\n              Left\n            "),
                ]),
                _c("p-button", [
                  _c("i", {
                    staticClass: "nc-icon nc-minimal-right",
                    attrs: { slot: "labelRight" },
                    slot: "labelRight",
                  }),
                  _vm._v("\n              Right\n            "),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("p-button", { attrs: { type: "primary", size: "sm" } }, [
                  _vm._v("Small"),
                ]),
                _c("p-button", { attrs: { type: "primary" } }, [
                  _vm._v("Regular"),
                ]),
                _c("p-button", { attrs: { type: "primary", size: "lg" } }, [
                  _vm._v("Large"),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(3),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("p-button", { attrs: { type: "default" } }, [
                  _vm._v("Default"),
                ]),
                _c("p-button", { attrs: { type: "default", round: "" } }, [
                  _vm._v("Round"),
                ]),
                _c(
                  "p-button",
                  { attrs: { type: "default", icon: "", round: "" } },
                  [_c("i", { staticClass: "fa fa-heart" })]
                ),
                _c(
                  "p-button",
                  { attrs: { type: "default", outline: "", round: "" } },
                  [
                    _c("i", { staticClass: "fa fa-heart" }),
                    _vm._v(" with icon\n            "),
                  ]
                ),
                _c("p-button", { attrs: { type: "default", link: "" } }, [
                  _vm._v("\n              link\n            "),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(4),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "nav",
                { attrs: { "aria-label": "Page navigation example" } },
                [
                  _c("p-pagination", {
                    attrs: { "page-count": 10 },
                    model: {
                      value: _vm.defaultPagination,
                      callback: function ($$v) {
                        _vm.defaultPagination = $$v
                      },
                      expression: "defaultPagination",
                    },
                  }),
                  _c("p-pagination", {
                    attrs: { type: "info", "page-count": 10 },
                    model: {
                      value: _vm.infoPagination,
                      callback: function ($$v) {
                        _vm.infoPagination = $$v
                      },
                      expression: "infoPagination",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(5),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "btn-group" },
                [
                  _c(
                    "p-button",
                    { attrs: { type: "info", round: "", outline: "" } },
                    [_vm._v("Left")]
                  ),
                  _c(
                    "p-button",
                    { attrs: { type: "info", round: "", outline: "" } },
                    [_vm._v("Middle")]
                  ),
                  _c(
                    "p-button",
                    { attrs: { type: "info", round: "", outline: "" } },
                    [_vm._v("Right")]
                  ),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c(
                "div",
                {
                  staticClass: "btn-group",
                  attrs: { "data-toggle": "buttons" },
                },
                [
                  _c(
                    "p-button",
                    { attrs: { type: "info", round: "", outline: "" } },
                    [_vm._v("1")]
                  ),
                  _c(
                    "p-button",
                    { attrs: { type: "info", round: "", outline: "" } },
                    [_vm._v("2")]
                  ),
                  _c(
                    "p-button",
                    { attrs: { type: "info", round: "", outline: "" } },
                    [_vm._v("3")]
                  ),
                  _c(
                    "p-button",
                    { attrs: { type: "info", round: "", outline: "" } },
                    [_vm._v("4")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btn-group" },
                [
                  _c(
                    "p-button",
                    { attrs: { type: "info", round: "", outline: "" } },
                    [_vm._v("5")]
                  ),
                  _c(
                    "p-button",
                    { attrs: { type: "info", round: "", outline: "" } },
                    [_vm._v("6")]
                  ),
                  _c(
                    "p-button",
                    { attrs: { type: "info", round: "", outline: "" } },
                    [_vm._v("7")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._m(6),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Pick your Color")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Buttons with Label")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Pick your Size")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Pick your Style")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Pagination")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Button Group")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h4", { staticClass: "card-title" }, [_vm._v("Social buttons")]),
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("p", { staticClass: "category" }, [_vm._v("Default")]),
              _c("button", { staticClass: "btn btn-twitter" }, [
                _c("i", { staticClass: "fa fa-twitter" }),
                _vm._v(" Connect with Twitter\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("p", { staticClass: "category" }, [_vm._v(" ")]),
              _c("button", { staticClass: "btn btn-icon btn-twitter" }, [
                _c("i", { staticClass: "fa fa-twitter" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("p", { staticClass: "category" }, [_vm._v(" ")]),
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-twitter" },
                [_c("i", { staticClass: "fa fa-twitter" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("p", { staticClass: "category" }, [_vm._v("Neutral")]),
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-twitter" },
                [_c("i", { staticClass: "fa fa-twitter" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("p", { staticClass: "category" }, [_vm._v(" ")]),
              _c("button", { staticClass: "btn btn-neutral btn-twitter" }, [
                _c("i", { staticClass: "fa fa-twitter" }),
                _vm._v(" Connect with Twitter\n                "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("button", { staticClass: "btn btn-facebook" }, [
                _c("i", { staticClass: "fa fa-facebook-square" }),
                _vm._v(" Share · 2.2k\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("button", { staticClass: "btn btn-icon btn-facebook" }, [
                _c("i", { staticClass: "fa fa-facebook-f" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-facebook" },
                [_c("i", { staticClass: "fa fa-facebook-f" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-facebook" },
                [_c("i", { staticClass: "fa fa-facebook-square" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("button", { staticClass: "btn btn-neutral btn-facebook" }, [
                _c("i", { staticClass: "fa fa-facebook-square" }),
                _vm._v(" Share · 2.2k\n                "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("button", { staticClass: "btn btn-google" }, [
                _c("i", { staticClass: "fa fa-google-plus" }),
                _vm._v(" Share on Google+\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("button", { staticClass: "btn btn-icon btn-google" }, [
                _c("i", { staticClass: "fa fa-google-plus" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-google" },
                [_c("i", { staticClass: "fa fa-google-plus" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-google" },
                [_c("i", { staticClass: "fa fa-google-plus" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("button", { staticClass: "btn btn-neutral btn-google" }, [
                _c("i", { staticClass: "fa fa-google-plus" }),
                _vm._v(" Share on Google+\n                "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("button", { staticClass: "btn btn-linkedin" }, [
                _c("i", { staticClass: "fa fa-linkedin" }),
                _vm._v(" Connect with Linkedin\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("button", { staticClass: "btn btn-icon btn-linkedin" }, [
                _c("i", { staticClass: "fa fa-linkedin" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-linkedin" },
                [_c("i", { staticClass: "fa fa-linkedin" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-linkedin" },
                [_c("i", { staticClass: "fa fa-linkedin" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("button", { staticClass: "btn btn-neutral btn-linkedin" }, [
                _c("i", { staticClass: "fa fa-linkedin" }),
                _vm._v(" Connect with Linkedin\n                "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("button", { staticClass: "btn btn-pinterest" }, [
                _c("i", { staticClass: "fa fa-pinterest" }),
                _vm._v(" Pint it · 212\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("button", { staticClass: "btn btn-icon btn-pinterest" }, [
                _c("i", { staticClass: "fa fa-pinterest" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-pinterest" },
                [_c("i", { staticClass: "fa fa-pinterest" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-pinterest" },
                [_c("i", { staticClass: "fa fa-pinterest" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("button", { staticClass: "btn btn-neutral btn-pinterest" }, [
                _c("i", { staticClass: "fa fa-pinterest" }),
                _vm._v(" Pint it · 212\n                "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("button", { staticClass: "btn btn-youtube" }, [
                _c("i", { staticClass: "fa fa-youtube" }),
                _vm._v(" View on Youtube\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("button", { staticClass: "btn btn-icon btn-youtube" }, [
                _c("i", { staticClass: "fa fa-youtube" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-youtube" },
                [_c("i", { staticClass: "fa fa-youtube" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-youtube" },
                [_c("i", { staticClass: "fa fa-youtube" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("button", { staticClass: "btn btn-neutral btn-youtube" }, [
                _c("i", { staticClass: "fa fa-youtube" }),
                _vm._v(" View on Youtube\n                "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("button", { staticClass: "btn btn-tumblr" }, [
                _c("i", { staticClass: "fa fa-tumblr-square" }),
                _vm._v(" Repost\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("button", { staticClass: "btn btn-icon btn-tumblr" }, [
                _c("i", { staticClass: "fa fa-tumblr" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-tumblr" },
                [_c("i", { staticClass: "fa fa-tumblr" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-tumblr" },
                [_c("i", { staticClass: "fa fa-tumblr-square" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("button", { staticClass: "btn btn-neutral btn-tumblr" }, [
                _c("i", { staticClass: "fa fa-tumblr-square" }),
                _vm._v(" Repost\n                "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("button", { staticClass: "btn btn-github" }, [
                _c("i", { staticClass: "fa fa-github" }),
                _vm._v(" Connect with Github\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("button", { staticClass: "btn btn-icon btn-github" }, [
                _c("i", { staticClass: "fa fa-github" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-github" },
                [_c("i", { staticClass: "fa fa-github" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-github" },
                [_c("i", { staticClass: "fa fa-github" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("button", { staticClass: "btn btn-neutral btn-github" }, [
                _c("i", { staticClass: "fa fa-github" }),
                _vm._v(" Connect with Github\n                "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("button", { staticClass: "btn btn-behance" }, [
                _c("i", { staticClass: "fa fa-behance-square" }),
                _vm._v(" Follow us\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("button", { staticClass: "btn btn-icon btn-behance" }, [
                _c("i", { staticClass: "fa fa-behance" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-behance" },
                [_c("i", { staticClass: "fa fa-behance" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-behance" },
                [_c("i", { staticClass: "fa fa-behance" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("button", { staticClass: "btn btn-neutral btn-behance" }, [
                _c("i", { staticClass: "fa fa-behance-square" }),
                _vm._v(" Follow us\n                "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("button", { staticClass: "btn btn-dribbble" }, [
                _c("i", { staticClass: "fa fa-dribbble" }),
                _vm._v(" Find us on Dribble\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("button", { staticClass: "btn btn-icon btn-dribbble" }, [
                _c("i", { staticClass: "fa fa-dribbble" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-dribbble" },
                [_c("i", { staticClass: "fa fa-dribbble" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-dribbble" },
                [_c("i", { staticClass: "fa fa-dribbble" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("button", { staticClass: "btn btn-neutral btn-dribbble" }, [
                _c("i", { staticClass: "fa fa-dribbble" }),
                _vm._v(" Find us on Dribble\n                "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-5" }, [
              _c("button", { staticClass: "btn btn-reddit" }, [
                _c("i", { staticClass: "fa fa-reddit" }),
                _vm._v(" Repost · 232\n                "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c("button", { staticClass: "btn btn-icon btn-reddit" }, [
                _c("i", { staticClass: "fa fa-reddit" }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-round btn-reddit" },
                [_c("i", { staticClass: "fa fa-reddit" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-1 col-sm-1 col-3" }, [
              _c(
                "button",
                { staticClass: "btn btn-icon btn-neutral btn-reddit" },
                [_c("i", { staticClass: "fa fa-reddit" })]
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-sm-4" }, [
              _c("button", { staticClass: "btn btn-neutral btn-reddit" }, [
                _c("i", { staticClass: "fa fa-reddit" }),
                _vm._v(" Repost · 232\n                "),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }