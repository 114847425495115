var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "progress" },
    [
      _vm.values.length === 0
        ? _c(
            "div",
            {
              staticClass: "progress-bar",
              class: `progress-bar-${_vm.type}`,
              style: `width: ${_vm.value}%;`,
              attrs: {
                role: "progressbar",
                "aria-valuenow": _vm.value,
                "aria-valuemin": "0",
                "aria-valuemax": "100",
              },
            },
            [_c("span", { staticClass: "sr-only" }, [_vm._t("default")], 2)]
          )
        : _vm._l(_vm.values, function (progress) {
            return _c(
              "div",
              {
                staticClass: "progress-bar",
                class: `progress-bar-${progress.type}`,
                style: `width: ${progress.value}%;`,
              },
              [_c("span", { staticClass: "sr-only" })]
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }