<template>
  <div class="user">
    <div class="photo">
      <!--<img src="/static/img/logo_sm.png" alt="">-->
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
         <span>
           {{ name }}
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" style="padding-left: 44px;" v-show="!isClosed">
            <li>
              <a href="#" @click="showModalPassowrd = true">
                <span class="sidebar-mini-icon"></span>
                <span class="sidebar-normal">Đổi mật khẩu</span>
              </a>
            </li>
            <li>
              <a href="#" @click="logout">
                <span class="sidebar-mini-icon"></span>
                <span class="sidebar-normal">Đăng xuất</span>
              </a>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
    <el-dialog
      title="Đổi mật khẩu"
      :visible.sync="showModalPassowrd"
      width="30%">
      <div class="old-password">
        <el-input
          placeholder="Mật khẩu cũ"
          type="password"
          v-model="old_password"
          class="full-width old-password"
          :class="errors.has('old_password')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="old_password"
              data-vv-as="Mật khẩu cũ"
        ></el-input>
        <span class="text-danger" v-if="errors.has('old_password')">{{ errors.first('old_password') }}</span>
      </div>
      
      <el-input
        placeholder="Mật khẩu mới"
        type="password"
        v-model="new_password"
        class="full-width"
        :class="errors.has('new_password')?'border-danger':''"
            v-validate="'required'"
            data-vv-name="new_password"
            data-vv-as="Mật khẩu mới"
      ></el-input>
      <span class="text-danger" v-if="errors.has('new_password')">{{ errors.first('new_password') }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModalPassowrd = false">Hủy</el-button>
        <el-button type="primary" @click="updatePassword">Đồng ý</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions'
  import { Message, Dialog, Button, Input } from 'element-ui';
  import { Toast } from 'vuex-toast';
  import { mapActions } from 'vuex';
  import { ADD_TOAST_MESSAGE } from 'vuex-toast';

  export default {
    components: {
      CollapseTransition,
      Message,
      ElDialog: Dialog,
      ElButton: Button,
      ElInput: Input
    },
    data() {
      return {
        isClosed: true,
        name: '',
        current_user: localStorage.getItem('current_user') ? JSON.parse(localStorage.getItem('current_user')) : '',
        showModalPassowrd: false,
        old_password: '',
        new_password: ''
      }
    },
    mounted() {
      this.name = this.current_user.name || 'iRace'
    },
    methods: {
      ...mapActions({
        addToast: ADD_TOAST_MESSAGE
      }),
      toastr(text, type) {
        this.addToast({
          text,
          type: type,
          dismissAfter: 3000
        })
      },
      toggleMenu() {
        this.isClosed = !this.isClosed
      },
      logout(){
        this.$store.dispatch('authLogout').then(res => {
          Message({
            message: 'Đăng xuất thành công',
            type: 'success'
          });
          this.$router.push({name: 'Login'})
        })
      },
      updatePassword() {
        let current_user = localStorage.getItem('current_user');
        if (!current_user) {
          Message({
            message: 'Chưa đăng nhập',
            type: 'error'
          });
          return false;
        }
        current_user = JSON.parse(current_user);
        var self = this;
        this.$validator.validateAll().then(function (result) {
          if (result) {
            let data = {
              old_password: self.old_password,
              new_password: self.new_password,
              user_id: current_user.id
            };
            self.$store.dispatch('updatePassword', data).then(res => {
              if (res.success) {
                Message({
                  message: 'Đổi mật khẩu thành công',
                  type: 'success'
                });
                self.showModalPassowrd = false;
              } else {
                Message({
                  message: res.message,
                  type: 'error'
                });
              }
            }, error => {
              console.error(error);
            });
          }
        });
      }
    }
  }
</script>
<style lang="scss">
  .nav.nav-menu {
    margin-top: 0;
  }
  // .sidebar {
  //   z-index: 5000 !important;
  // }

  .old-password {
    margin-bottom: 10px;
  }

</style>
