var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", { staticClass: "footer footer-black footer-white" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("nav", { staticClass: "footer-nav" }, [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.creative-tim.com",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Creative Tim")]
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "http://blog.creative-tim.com/",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Blog")]
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.creative-tim.com/license",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Licenses")]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "credits ml-auto" }, [
            _c("div", { staticClass: "copyright" }, [
              _vm._v("\n          © Coded with\n          "),
              _c("i", { staticClass: "fa fa-heart heart" }),
              _vm._v(" by\n          "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://github.com/cristijora",
                    target: "_blank",
                  },
                },
                [_vm._v("Cristi Jora")]
              ),
              _vm._v(".\n          Designed by "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.creative-tim.com/?ref=pdf-vuejs",
                    target: "_blank",
                  },
                },
                [_vm._v("Creative Tim")]
              ),
              _vm._v(".\n        "),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }