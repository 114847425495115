var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SlideYUpTransition",
    { attrs: { duration: _vm.animationDuration } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "modal fade",
          class: [
            { "show d-block": _vm.show },
            { "d-none": !_vm.show },
            { "modal-mini": _vm.type === "mini" },
          ],
          attrs: { tabindex: "-1", role: "dialog", "aria-hidden": !_vm.show },
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.closeModal.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog",
              class: [
                { "modal-notice": _vm.type === "notice" },
                _vm.modalClasses,
              ],
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  { staticClass: "modal-header", class: _vm.headerClasses },
                  [
                    _vm._t("close-button", function () {
                      return [
                        _vm.showClose
                          ? _c(
                              "button",
                              {
                                staticClass: "close",
                                attrs: {
                                  type: "button",
                                  "data-dismiss": "modal",
                                  "aria-hidden": !_vm.show,
                                },
                                on: { click: _vm.closeModal },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "now-ui-icons ui-1_simple-remove",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                    _vm._t("header"),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "modal-body", class: _vm.bodyClasses },
                  [_vm._t("default")],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "modal-footer", class: _vm.footerClasses },
                  [_vm._t("footer")],
                  2
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }