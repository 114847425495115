var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card demo-icons" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h5", { staticClass: "card-title" }, [
                _vm._v("100 Awesome Nucleo Icons"),
              ]),
              _c("p", { staticClass: "card-category" }, [
                _vm._v("Handcrafted by our friends from\n            "),
                _c(
                  "a",
                  { attrs: { href: "https://nucleoapp.com/?ref=1712" } },
                  [_vm._v("NucleoApp")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "card-body all-icons" }, [
              _c("div", { attrs: { id: "icons-wrapper" } }, [
                _c("section", [
                  _c("ul", [
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-air-baloon" }),
                      _c("p", [_vm._v("nc-air-baloon")]),
                      _c("em", [_vm._v("\\ea01")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-album-2" }),
                      _c("p", [_vm._v("nc-album-2")]),
                      _c("em", [_vm._v("\\ea02")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-alert-circle-i" }),
                      _c("p", [_vm._v("nc-alert-circle-i")]),
                      _c("em", [_vm._v("\\ea04")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-align-center" }),
                      _c("p", [_vm._v("nc-align-center")]),
                      _c("em", [_vm._v("\\ea03")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-align-left-2" }),
                      _c("p", [_vm._v("nc-align-left-2")]),
                      _c("em", [_vm._v("\\ea05")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-ambulance" }),
                      _c("p", [_vm._v("nc-ambulance")]),
                      _c("em", [_vm._v("\\ea06")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-app" }),
                      _c("p", [_vm._v("nc-app")]),
                      _c("em", [_vm._v("\\ea07")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-atom" }),
                      _c("p", [_vm._v("nc-atom")]),
                      _c("em", [_vm._v("\\ea08")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-badge" }),
                      _c("p", [_vm._v("nc-badge")]),
                      _c("em", [_vm._v("\\ea09")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-bag-16" }),
                      _c("p", [_vm._v("nc-bag-16")]),
                      _c("em", [_vm._v("\\ea0a")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-bank" }),
                      _c("p", [_vm._v("nc-bank")]),
                      _c("em", [_vm._v("\\ea0b")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-basket" }),
                      _c("p", [_vm._v("nc-basket")]),
                      _c("em", [_vm._v("\\ea0c")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-bell-55" }),
                      _c("p", [_vm._v("nc-bell-55")]),
                      _c("em", [_vm._v("\\ea0d")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-bold" }),
                      _c("p", [_vm._v("nc-bold")]),
                      _c("em", [_vm._v("\\ea0e")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-book-bookmark" }),
                      _c("p", [_vm._v("nc-book-bookmark")]),
                      _c("em", [_vm._v("\\ea0f")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-bookmark-2" }),
                      _c("p", [_vm._v("nc-bookmark-2")]),
                      _c("em", [_vm._v("\\ea10")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-box-2" }),
                      _c("p", [_vm._v("nc-box-2")]),
                      _c("em", [_vm._v("\\ea11")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-box" }),
                      _c("p", [_vm._v("nc-box")]),
                      _c("em", [_vm._v("\\ea12")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-briefcase-24" }),
                      _c("p", [_vm._v("nc-briefcase-24")]),
                      _c("em", [_vm._v("\\ea13")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-bulb-63" }),
                      _c("p", [_vm._v("nc-bulb-63")]),
                      _c("em", [_vm._v("\\ea14")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-bullet-list-67" }),
                      _c("p", [_vm._v("nc-bullet-list-67")]),
                      _c("em", [_vm._v("\\ea15")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-bus-front-12" }),
                      _c("p", [_vm._v("nc-bus-front-12")]),
                      _c("em", [_vm._v("\\ea16")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-button-pause" }),
                      _c("p", [_vm._v("nc-button-pause")]),
                      _c("em", [_vm._v("\\ea17")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-button-play" }),
                      _c("p", [_vm._v("nc-button-play")]),
                      _c("em", [_vm._v("\\ea18")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-button-power" }),
                      _c("p", [_vm._v("nc-button-power")]),
                      _c("em", [_vm._v("\\ea19")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-calendar-60" }),
                      _c("p", [_vm._v("nc-calendar-60")]),
                      _c("em", [_vm._v("\\ea1a")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-camera-compact" }),
                      _c("p", [_vm._v("nc-camera-compact")]),
                      _c("em", [_vm._v("\\ea1b")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-caps-small" }),
                      _c("p", [_vm._v("nc-caps-small")]),
                      _c("em", [_vm._v("\\ea1c")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-cart-simple" }),
                      _c("p", [_vm._v("nc-cart-simple")]),
                      _c("em", [_vm._v("\\ea1d")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-chart-bar-32" }),
                      _c("p", [_vm._v("nc-chart-bar-32")]),
                      _c("em", [_vm._v("\\ea1e")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-chart-pie-36" }),
                      _c("p", [_vm._v("nc-chart-pie-36")]),
                      _c("em", [_vm._v("\\ea1f")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-chat-33" }),
                      _c("p", [_vm._v("nc-chat-33")]),
                      _c("em", [_vm._v("\\ea20")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-check-2" }),
                      _c("p", [_vm._v("nc-check-2")]),
                      _c("em", [_vm._v("\\ea21")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-circle-10" }),
                      _c("p", [_vm._v("nc-circle-10")]),
                      _c("em", [_vm._v("\\ea22")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-cloud-download-93" }),
                      _c("p", [_vm._v("nc-cloud-download-93")]),
                      _c("em", [_vm._v("\\ea23")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-cloud-upload-94" }),
                      _c("p", [_vm._v("nc-cloud-upload-94")]),
                      _c("em", [_vm._v("\\ea24")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-compass-05" }),
                      _c("p", [_vm._v("nc-compass-05")]),
                      _c("em", [_vm._v("\\ea25")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-controller-modern" }),
                      _c("p", [_vm._v("nc-controller-modern")]),
                      _c("em", [_vm._v("\\ea26")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-credit-card" }),
                      _c("p", [_vm._v("nc-credit-card")]),
                      _c("em", [_vm._v("\\ea27")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-delivery-fast" }),
                      _c("p", [_vm._v("nc-delivery-fast")]),
                      _c("em", [_vm._v("\\ea28")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-diamond" }),
                      _c("p", [_vm._v("nc-diamond")]),
                      _c("em", [_vm._v("\\ea29")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-email-85" }),
                      _c("p", [_vm._v("nc-email-85")]),
                      _c("em", [_vm._v("\\ea2a")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-favourite-28" }),
                      _c("p", [_vm._v("nc-favourite-28")]),
                      _c("em", [_vm._v("\\ea2b")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-glasses-2" }),
                      _c("p", [_vm._v("nc-glasses-2")]),
                      _c("em", [_vm._v("\\ea2c")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-globe-2" }),
                      _c("p", [_vm._v("nc-globe-2")]),
                      _c("em", [_vm._v("\\ea2d")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-globe" }),
                      _c("p", [_vm._v("nc-globe")]),
                      _c("em", [_vm._v("\\ea2e")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-hat-3" }),
                      _c("p", [_vm._v("nc-hat-3")]),
                      _c("em", [_vm._v("\\ea2f")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-headphones" }),
                      _c("p", [_vm._v("nc-headphones")]),
                      _c("em", [_vm._v("\\ea30")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-html5" }),
                      _c("p", [_vm._v("nc-html5")]),
                      _c("em", [_vm._v("\\ea31")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-image" }),
                      _c("p", [_vm._v("nc-image")]),
                      _c("em", [_vm._v("\\ea32")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-istanbul" }),
                      _c("p", [_vm._v("nc-istanbul")]),
                      _c("em", [_vm._v("\\ea33")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-key-25" }),
                      _c("p", [_vm._v("nc-key-25")]),
                      _c("em", [_vm._v("\\ea34")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-laptop" }),
                      _c("p", [_vm._v("nc-laptop")]),
                      _c("em", [_vm._v("\\ea35")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-layout-11" }),
                      _c("p", [_vm._v("nc-layout-11")]),
                      _c("em", [_vm._v("\\ea36")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-lock-circle-open" }),
                      _c("p", [_vm._v("nc-lock-circle-open")]),
                      _c("em", [_vm._v("\\ea37")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-map-big" }),
                      _c("p", [_vm._v("nc-map-big")]),
                      _c("em", [_vm._v("\\ea38")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-minimal-down" }),
                      _c("p", [_vm._v("nc-minimal-down")]),
                      _c("em", [_vm._v("\\ea39")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-minimal-left" }),
                      _c("p", [_vm._v("nc-minimal-left")]),
                      _c("em", [_vm._v("\\ea3a")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-minimal-right" }),
                      _c("p", [_vm._v("nc-minimal-right")]),
                      _c("em", [_vm._v("\\ea3b")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-minimal-up" }),
                      _c("p", [_vm._v("nc-minimal-up")]),
                      _c("em", [_vm._v("\\ea3c")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-mobile" }),
                      _c("p", [_vm._v("nc-mobile")]),
                      _c("em", [_vm._v("\\ea3d")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-money-coins" }),
                      _c("p", [_vm._v("nc-money-coins")]),
                      _c("em", [_vm._v("\\ea3e")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-note-03" }),
                      _c("p", [_vm._v("nc-note-03")]),
                      _c("em", [_vm._v("\\ea3f")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-palette" }),
                      _c("p", [_vm._v("nc-palette")]),
                      _c("em", [_vm._v("\\ea40")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-paper" }),
                      _c("p", [_vm._v("nc-paper")]),
                      _c("em", [_vm._v("\\ea41")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-pin-3" }),
                      _c("p", [_vm._v("nc-pin-3")]),
                      _c("em", [_vm._v("\\ea42")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-planet" }),
                      _c("p", [_vm._v("nc-planet")]),
                      _c("em", [_vm._v("\\ea43")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-refresh-69" }),
                      _c("p", [_vm._v("nc-refresh-69")]),
                      _c("em", [_vm._v("\\ea44")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-ruler-pencil" }),
                      _c("p", [_vm._v("nc-ruler-pencil")]),
                      _c("em", [_vm._v("\\ea45")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-satisfied" }),
                      _c("p", [_vm._v("nc-satisfied")]),
                      _c("em", [_vm._v("\\ea46")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-scissors" }),
                      _c("p", [_vm._v("nc-scissors")]),
                      _c("em", [_vm._v("\\ea47")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-send" }),
                      _c("p", [_vm._v("nc-send")]),
                      _c("em", [_vm._v("\\ea48")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-settings-gear-65" }),
                      _c("p", [_vm._v("nc-settings-gear-65")]),
                      _c("em", [_vm._v("\\ea49")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-settings" }),
                      _c("p", [_vm._v("nc-settings")]),
                      _c("em", [_vm._v("\\ea4a")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-share-66" }),
                      _c("p", [_vm._v("nc-share-66")]),
                      _c("em", [_vm._v("\\ea4b")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-shop" }),
                      _c("p", [_vm._v("nc-shop")]),
                      _c("em", [_vm._v("\\ea4c")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-simple-add" }),
                      _c("p", [_vm._v("nc-simple-add")]),
                      _c("em", [_vm._v("\\ea4d")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-simple-delete" }),
                      _c("p", [_vm._v("nc-simple-delete")]),
                      _c("em", [_vm._v("\\ea4e")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-simple-remove" }),
                      _c("p", [_vm._v("nc-simple-remove")]),
                      _c("em", [_vm._v("\\ea4f")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-single-02" }),
                      _c("p", [_vm._v("nc-single-02")]),
                      _c("em", [_vm._v("\\ea50")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-single-copy-04" }),
                      _c("p", [_vm._v("nc-single-copy-04")]),
                      _c("em", [_vm._v("\\ea51")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-sound-wave" }),
                      _c("p", [_vm._v("nc-sound-wave")]),
                      _c("em", [_vm._v("\\ea52")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-spaceship" }),
                      _c("p", [_vm._v("nc-spaceship")]),
                      _c("em", [_vm._v("\\ea53")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-sun-fog-29" }),
                      _c("p", [_vm._v("nc-sun-fog-29")]),
                      _c("em", [_vm._v("\\ea54")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-support-17" }),
                      _c("p", [_vm._v("nc-support-17")]),
                      _c("em", [_vm._v("\\ea55")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-tablet-2" }),
                      _c("p", [_vm._v("nc-tablet-2")]),
                      _c("em", [_vm._v("\\ea56")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-tag-content" }),
                      _c("p", [_vm._v("nc-tag-content")]),
                      _c("em", [_vm._v("\\ea57")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-tap-01" }),
                      _c("p", [_vm._v("nc-tap-01")]),
                      _c("em", [_vm._v("\\ea58")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-tie-bow" }),
                      _c("p", [_vm._v("nc-tie-bow")]),
                      _c("em", [_vm._v("\\ea59")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-tile-56" }),
                      _c("p", [_vm._v("nc-tile-56")]),
                      _c("em", [_vm._v("\\ea5a")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-time-alarm" }),
                      _c("p", [_vm._v("nc-time-alarm")]),
                      _c("em", [_vm._v("\\ea5b")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-touch-id" }),
                      _c("p", [_vm._v("nc-touch-id")]),
                      _c("em", [_vm._v("\\ea5c")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-trophy" }),
                      _c("p", [_vm._v("nc-trophy")]),
                      _c("em", [_vm._v("\\ea5d")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-tv-2" }),
                      _c("p", [_vm._v("nc-tv-2")]),
                      _c("em", [_vm._v("\\ea5e")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-umbrella-13" }),
                      _c("p", [_vm._v("nc-umbrella-13")]),
                      _c("em", [_vm._v("\\ea5f")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-user-run" }),
                      _c("p", [_vm._v("nc-user-run")]),
                      _c("em", [_vm._v("\\ea60")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-vector" }),
                      _c("p", [_vm._v("nc-vector")]),
                      _c("em", [_vm._v("\\ea61")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-watch-time" }),
                      _c("p", [_vm._v("nc-watch-time")]),
                      _c("em", [_vm._v("\\ea62")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-world-2" }),
                      _c("p", [_vm._v("nc-world-2")]),
                      _c("em", [_vm._v("\\ea63")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("i", { staticClass: "nc-icon nc-zoom-split" }),
                      _c("p", [_vm._v("nc-zoom-split")]),
                      _c("em", [_vm._v("\\ea64")]),
                      _c("input", {
                        attrs: {
                          type: "text",
                          maxlength: "1",
                          readonly: "true",
                          value: "",
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }