var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card card-stats" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-5 col-md-4" },
          [
            _vm._t("header", function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "icon-big text-center",
                    class: `text-${_vm.type}`,
                  },
                  [_c("i", { class: _vm.icon })]
                ),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "col-7 col-md-8" },
          [
            _vm._t("content", function () {
              return [
                _c("div", { staticClass: "numbers" }, [
                  _c("p", { staticClass: "card-category" }, [
                    _vm._v(_vm._s(_vm.smallTitle)),
                  ]),
                  _c("p", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]),
              ]
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }