var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: [
        { "input-group": _vm.hasIcon },
        { "has-danger": _vm.error },
        { "input-group-focus": _vm.focused },
        { "has-label": _vm.label || _vm.$slots.label },
        { "has-success": _vm.hasSuccess },
      ],
    },
    [
      _vm._t("label", function () {
        return [
          _vm.label
            ? _c("label", { class: _vm.labelClasses }, [
                _vm._v("\n      " + _vm._s(_vm.label) + "\n      "),
                _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
              ])
            : _vm._e(),
        ]
      }),
      _vm.addonLeftIcon || _vm.$slots.addonLeft
        ? _c("div", { staticClass: "input-group-prepend" }, [
            _c(
              "span",
              { staticClass: "input-group-text" },
              [
                _vm._t("addonLeft", function () {
                  return [_c("i", { class: _vm.addonLeftIcon })]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._t("default", function () {
        return [
          _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  staticClass: "form-control",
                  class: [
                    { valid: _vm.value && !_vm.error },
                    _vm.inputClasses,
                    _vm.error ? "form-control-danger" : "",
                  ],
                  attrs: { "aria-describedby": "addon-right addon-left" },
                  domProps: { value: _vm.value },
                },
                "input",
                _vm.$attrs,
                false
              ),
              _vm.listeners
            )
          ),
        ]
      }),
      _vm.addonRightIcon || _vm.$slots.addonRight
        ? _c("div", { staticClass: "input-group-append" }, [
            _c(
              "span",
              { staticClass: "input-group-text" },
              [
                _vm._t("addonRight", function () {
                  return [_c("i", { class: _vm.addonRightIcon })]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._t("infoBlock"),
      _vm._t("helpBlock", function () {
        return [
          _vm.error
            ? _c(
                "div",
                {
                  staticClass: "text-danger invalid-feedback",
                  class: { "mt-2": _vm.hasIcon },
                  staticStyle: { display: "block" },
                },
                [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }