var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _c("app-navbar"),
      _c("div", { staticClass: "wrapper wrapper-full-page" }, [
        _c(
          "div",
          { staticClass: "full-page login-page section-image" },
          [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-4 col-md-6 ml-auto mr-auto" },
                  [
                    _c(
                      "info-section",
                      {
                        staticClass: "mt-5",
                        attrs: {
                          type: "danger",
                          title: "Marketing",
                          description:
                            "We've created the marketing campaign of the website. It was a very interesting collaboration.",
                          icon: "nc-icon nc-alert-circle-i",
                        },
                      },
                      [
                        _c("h2", { staticClass: "text-white mt-3" }, [
                          _vm._v("Page Not Found"),
                        ]),
                        _c(
                          "small",
                          { staticClass: "text-white" },
                          [
                            _vm._v(
                              "Oops! It seems that this page does not exist.\n                "
                            ),
                            _c("br"),
                            _vm._v("\n                You can navigate back "),
                            _c("router-link", { attrs: { to: "/" } }, [
                              _vm._v("to main page"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("app-footer"),
            _c("div", {
              staticClass: "full-page-background",
              staticStyle: {
                "background-image":
                  "url(static/img/background/jan-sendereks.jpg)",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }