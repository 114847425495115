import _ from 'lodash'
import Vue from 'vue';
let $root = {};
import moment from 'moment';

const nameHash = {
  'products': 'product',
  'product': 'product',

  'collections': 'collection',
  'collection': 'collection',

  'articles': 'article',
  'article': 'article',

  'blogs': 'blog',
  'blog': 'blog',

  'pages': 'page',
  'page': 'page',

  'galleries': 'gallery',
  'gallery': 'gallery',

  'customers': 'customer',
  'customer': 'customer',

  'roles': 'role',
  'role': 'role',
}

const multipleTypes = [
  'products', 'collections', 'articles', 'blogs', 'pages', 'galleries', 'roles', 'customers'
]

const util = {
  findModel(_name) {
    let name = _name.toLowerCase();
    return nameHash[name];
  },
  isMultiple(type) {
    return _.includes(multipleTypes, type);
  },
  nestedSet(hash, field, value) {
    let levels = field.split('.');
    let obj = hash;
    let lastLevel = levels.pop();
    for (let level of levels) {
      if (!obj[level]) {
        obj[level] = {};
      }
      obj = obj[level];
    }
    obj[lastLevel] = value;
  },
  nestedGet(hash, field) {
    let levels = field.split('.');
    let obj = hash;
    let lastLevel = levels.pop();
    for (let level of levels) {
      if (!obj[level]) {
        obj[level] = {};
      }
      obj = obj[level];
    }
    return obj[lastLevel];
  },

  hashFlatten(hash) {
    // {seo: {meta_title: ''}} => seo.meta_title
    let result = {};

    function setValue(hash, result, parent) {
      for (let key of Object.keys(hash)) {
        if (typeof hash[key] != 'object') {
          result[parent + key] = hash[key];
        } else {
          setValue(hash[key], result, parent + key + '.')
        }
      }
      return result;
    }

    return setValue(hash, result, '');
  },
  hashNested(hash) {
    let result = {};
    // seo.meta_title => {seo: {meta_title: ''}}
    for (let key of Object.keys(hash)) {
      util.nestedSet(result, key, hash[key]);
    }
    return result;
  },
  createHandle(str) {
    if (str) {
      str = str + '';
      str = str.trim();
      str = str.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/\,/g, '-');
      str = str.replace(/\./g, '-');
      str = str.replace(/\!/g, '-');
      str = str.replace(/\?/g, '-');
      str = str.replace(/\~/g, '-');
      str = str.replace(/\ /g, '-');
      str = str.replace(/\|/g, '-');
      str = str.replace(/\./g, '-');
      str = str.replace(/\"/g, '-');
      str = str.replace(/\'/g, '-');
      str = str.replace(/\-\-+/g, '-');
      str = str.replace(/\s+/g, '-');
      str = str.replace(/[^\w\-]+/g, '');
      str = str.replace(/\-\-+/g, '-');
      str = str.replace(/^-+/, '');
      str = str.replace(/-+$/, '');
      if (str.slice(-1) == '-') str = str.substring(0, str.length - 1);
    }
    return str;
  },

  parseMessageError422(errors) {
    if (typeof errors !== 'object') return '';
    let messages = '';
    for (let item in errors) {
      messages += `<div style="margin-bottom: 5px;">${errors[item][0]}</div>`;
    }
    return messages;
  },

  formatMoney(value) {
    let val = (value/1).toFixed(0).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'đ';
  },

  validateEmail(email) {
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  },

  canvasRender(imageUrl, canvas, callback) {
    let ctx = canvas.getContext('2d');
    let img = new Image();
    img.onload = function() {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      callback(canvas, ctx, imageUrl);
    }
    img.src = imageUrl;
    img.setAttribute('crossOrigin', 'anonymous');
  },
  parseCertData(obj) {
    return {
      fullname: obj.fullname,
      successText: 'successfully completed'.toUpperCase(),
      archivementText: `category: ${obj.category} | PACE ${obj.pace}`.toUpperCase(),
      timeText: obj.moving_time,
      rankText: 'RANKING: #' + obj.rank
    }
  },
  //obj: /api/userraces/:id => data = parseCertData(obj)
  renderCertCanvas(canvas, ctx, data) {
    const FONT_SIZE_LARGE = 120;
    const FONT_SIZE_MEDIUM = 110;
    const FONT_SIZE_SMALL = 40;
    const TOP_PERCENTAGE = 55;
    const PADDING_PERCENTAGE = 60;

    ctx.textAlign="center";
    let left = Math.floor((canvas.width) / 2);
    let top = (TOP_PERCENTAGE / 100) * canvas.height -  (FONT_SIZE_LARGE / 2);
    top = Math.floor(top);

    ctx.font= FONT_SIZE_LARGE + "px Arial";
    ctx.fillText(data.fullname, left, top);
    top += FONT_SIZE_LARGE * (PADDING_PERCENTAGE / 100);

    top += FONT_SIZE_SMALL * (PADDING_PERCENTAGE / 100);
    ctx.font= FONT_SIZE_SMALL + "px Arial";
    ctx.fillText(data.successText, left, top);
    top += FONT_SIZE_SMALL * (PADDING_PERCENTAGE / 100);

    top += FONT_SIZE_SMALL * (PADDING_PERCENTAGE / 100);
    ctx.font= FONT_SIZE_SMALL + "px Arial";
    ctx.fillText(data.archivementText, left, top);
    top += FONT_SIZE_SMALL * (PADDING_PERCENTAGE / 100);

    top += FONT_SIZE_MEDIUM * (PADDING_PERCENTAGE / 100);
    top += FONT_SIZE_SMALL * (PADDING_PERCENTAGE / 100);
    ctx.font= FONT_SIZE_MEDIUM + "px Arial";
    ctx.fillText(data.timeText, left, top);
    top += FONT_SIZE_MEDIUM * (PADDING_PERCENTAGE / 100);

    ctx.font= FONT_SIZE_SMALL + "px Arial";
    ctx.fillText(data.rankText, left, top);
  },
  //data: /api/userraces/:id
  renderBibCanvas(canvas, ctx, data) {
    const FONT_SIZE = 200;
    const TOP_PERCENTAGE = 80;
    const text = data.user_id;

    ctx.font= FONT_SIZE + "px Verdana";
    ctx.textAlign="center";

    let left = Math.floor((canvas.width) / 2);
    let top = (TOP_PERCENTAGE / 100) * canvas.height -  (FONT_SIZE / 2);
    top = Math.floor(top);

    ctx.fillText(text, left, top);
  },
  setCookie(name,value,days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  },
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  },
  eraseCookie(name) {
    this.setCookie(name, '', 0);
  },
  dateTimeFormat(datetime) {
    if (!datetime) return null;
    return moment(datetime).format("DD/MM/YYYY (HH:mm)");
  }
}

setTimeout(_ => {
  $root = window.vueApp.$root;
}, 500);


export default util;
