var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "time-line",
        [
          _c(
            "time-line-item",
            {
              staticClass: "timeline-inverted",
              attrs: {
                badgeType: "danger",
                badgeIcon: "nc-icon nc-single-copy-04",
              },
            },
            [
              _c(
                "badge",
                { attrs: { slot: "header", type: "danger" }, slot: "header" },
                [_vm._v("Some title")]
              ),
              _c("p", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v(
                  "\n        Wifey made the best Father's Day meal ever. So thankful so happy so blessed. Thank you for making my family We just had fun with the “future” theme !!!   It was a fun night all together ... The always rude Kanye Show at 2am Sold Out Famous viewing @ Figueroa and 12th in downtown."
                ),
              ]),
              _c("h6", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c("i", { staticClass: "fa fa-clock-o" }),
                _vm._v("\n        11 hours ago via Twitter\n      "),
              ]),
            ],
            1
          ),
          _c(
            "time-line-item",
            {
              attrs: {
                badgeType: "success",
                badgeIcon: "nc-icon nc-sun-fog-29",
              },
            },
            [
              _c(
                "badge",
                { attrs: { slot: "header", type: "success" }, slot: "header" },
                [_vm._v("Another Title")]
              ),
              _c("p", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v(
                  "\n        Thank God for the support of my wife and real friends. I also wanted to point out that it’s the first album to go number 1 off of streaming!!! I love you Ellen and also my number one design rule of anything I do from shoes to music to homes is that Kim has to like it...."
                ),
              ]),
            ],
            1
          ),
          _c(
            "time-line-item",
            {
              staticClass: "timeline-inverted",
              attrs: { badgeType: "info", badgeIcon: "nc-icon nc-world-2" },
            },
            [
              _c(
                "badge",
                { attrs: { slot: "header", type: "info" }, slot: "header" },
                [_vm._v("Another Title")]
              ),
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("p", [
                  _vm._v(
                    "\n          Called I Miss the Old Kanye That’s all it was Kanye And I love you like Kanye loves Kanye Famous viewing @ Figueroa and 12th in downtown LA 11:10PM"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "\n          What if Kanye made a song about Kanye Royère doesn't make a Polar bear bed but the Polar bear couch is my favorite piece of furniture we own It wasn’t any Kanyes Set on his goals Kanye"
                  ),
                ]),
                _c("hr"),
              ]),
              _c(
                "drop-down",
                {
                  staticClass: "btn-group",
                  attrs: { slot: "footer", position: "left" },
                  slot: "footer",
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function ({ isOpen }) {
                        return _c(
                          "p-button",
                          {
                            staticClass: "dropdown-toggle",
                            attrs: {
                              type: "info",
                              round: "",
                              "aria-expanded": isOpen,
                            },
                          },
                          [
                            _c("i", { staticClass: "nc-icon nc-settings" }),
                            _vm._v("  \n        "),
                          ]
                        )
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "a",
                    { staticClass: "dropdown-item", attrs: { href: "#" } },
                    [_vm._v("Action")]
                  ),
                  _c(
                    "a",
                    { staticClass: "dropdown-item", attrs: { href: "#" } },
                    [_vm._v("Another action")]
                  ),
                  _c(
                    "a",
                    { staticClass: "dropdown-item", attrs: { href: "#" } },
                    [_vm._v("Something else here")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "time-line-item",
            {
              attrs: { badgeType: "warning", badgeIcon: "nc-icon nc-istanbul" },
            },
            [
              _c(
                "badge",
                { attrs: { slot: "header", type: "warning" }, slot: "header" },
                [_vm._v("Another Title")]
              ),
              _c("p", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v(
                  "\n        Tune into Big Boy's 92.3 I'm about to play the first single from Cruel Winter Tune into Big Boy's 92.3 I'm about to play the first single from Cruel Winter also to Kim’s hair and makeup Lorraine jewelry and the whole style squad at Balmain and the Yeezy team. Thank you Anna for the invite thank you to the whole Vogue team"
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header text-center" }, [
      _c("h3", { staticClass: "title" }, [_vm._v("Timeline")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }