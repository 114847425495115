var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "navbar-toggler",
      attrs: { type: "button" },
      on: { click: _vm.showSidebar },
    },
    [
      _c("span", { staticClass: "navbar-toggler-bar bar1" }),
      _c("span", { staticClass: "navbar-toggler-bar bar2" }),
      _c("span", { staticClass: "navbar-toggler-bar bar3" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }