var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c(
            "card",
            [
              _c("template", { slot: "header" }, [
                _c("h5", { staticClass: "card-title" }, [
                  _vm._v("Notifications"),
                ]),
                _c("p", { staticClass: "card-category" }, [
                  _vm._v("Handcrafted by our friend\n            "),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://github.com/cristijora",
                      },
                    },
                    [_vm._v("Cristi Jora")]
                  ),
                  _vm._v(". Please checkout the\n            "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://github.com/cristijora/vue-notifyjs",
                        target: "_blank",
                      },
                    },
                    [_vm._v("full documentation.")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "card card-plain" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v("Notifications Style"),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "alert alert-info" }, [
                        _c("span", [_vm._v("This is a plain notification")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-info alert-dismissible fade show",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "aria-hidden": "true",
                                "data-dismiss": "alert",
                                "aria-label": "Close",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "nc-icon nc-simple-remove",
                              }),
                            ]
                          ),
                          _c("span", [
                            _vm._v("This is a notification with close button."),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-info alert-with-icon alert-dismissible fade show",
                          attrs: { "data-notify": "container" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "aria-hidden": "true",
                                "data-dismiss": "alert",
                                "aria-label": "Close",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "nc-icon nc-simple-remove",
                              }),
                            ]
                          ),
                          _c("span", {
                            staticClass: "nc-icon nc-bell-55",
                            attrs: { "data-notify": "icon" },
                          }),
                          _c("span", { attrs: { "data-notify": "message" } }, [
                            _vm._v(
                              "This is a notification with close button and icon."
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "card card-plain" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v("Notification states"),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-body" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-primary alert-dismissible fade show",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "aria-hidden": "true",
                                "data-dismiss": "alert",
                                "aria-label": "Close",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "nc-icon nc-simple-remove",
                              }),
                            ]
                          ),
                          _c("span", [
                            _c("b", [_vm._v(" Primary - ")]),
                            _vm._v(
                              ' This is a regular notification made with ".alert-primary"'
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-info alert-dismissible fade show",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "aria-hidden": "true",
                                "data-dismiss": "alert",
                                "aria-label": "Close",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "nc-icon nc-simple-remove",
                              }),
                            ]
                          ),
                          _c("span", [
                            _c("b", [_vm._v(" Info - ")]),
                            _vm._v(
                              ' This is a regular notification made with ".alert-info"'
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-success alert-dismissible fade show",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "aria-hidden": "true",
                                "data-dismiss": "alert",
                                "aria-label": "Close",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "nc-icon nc-simple-remove",
                              }),
                            ]
                          ),
                          _c("span", [
                            _c("b", [_vm._v(" Success - ")]),
                            _vm._v(
                              ' This is a regular notification made with ".alert-success"'
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-warning alert-dismissible fade show",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "aria-hidden": "true",
                                "data-dismiss": "alert",
                                "aria-label": "Close",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "nc-icon nc-simple-remove",
                              }),
                            ]
                          ),
                          _c("span", [
                            _c("b", [_vm._v(" Warning - ")]),
                            _vm._v(
                              ' This is a regular notification made with ".alert-warning"'
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-danger alert-dismissible fade show",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "aria-hidden": "true",
                                "data-dismiss": "alert",
                                "aria-label": "Close",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "nc-icon nc-simple-remove",
                              }),
                            ]
                          ),
                          _c("span", [
                            _c("b", [_vm._v(" Danger - ")]),
                            _vm._v(
                              ' This is a regular notification made with ".alert-danger"'
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("card", [
            _c("div", { staticClass: "places-buttons" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 ml-auto mr-auto text-center" },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(
                        "\n                Notifications Places\n                "
                      ),
                      _c("p", { staticClass: "category" }, [
                        _vm._v("Click to view notifications"),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-8 ml-auto mr-auto" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "p-button",
                          {
                            attrs: {
                              type: "default",
                              outline: "",
                              block: "",
                              round: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.notifyVue("top", "left")
                              },
                            },
                          },
                          [_vm._v("Top Left")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "p-button",
                          {
                            attrs: {
                              type: "default",
                              outline: "",
                              block: "",
                              round: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.notifyVue("top", "center")
                              },
                            },
                          },
                          [_vm._v("Top Center\n                  ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "p-button",
                          {
                            attrs: {
                              type: "default",
                              outline: "",
                              block: "",
                              round: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.notifyVue("top", "right")
                              },
                            },
                          },
                          [_vm._v("Top Right")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-8 ml-auto mr-auto" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "p-button",
                          {
                            attrs: {
                              type: "default",
                              outline: "",
                              block: "",
                              round: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.notifyVue("bottom", "left")
                              },
                            },
                          },
                          [_vm._v("Bottom Left")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "p-button",
                          {
                            attrs: {
                              type: "default",
                              outline: "",
                              block: "",
                              round: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.notifyVue("bottom", "center")
                              },
                            },
                          },
                          [_vm._v("Bottom Center\n                  ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "p-button",
                          {
                            attrs: {
                              type: "default",
                              outline: "",
                              block: "",
                              round: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.notifyVue("bottom", "right")
                              },
                            },
                          },
                          [_vm._v("Bottom Right")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12 text-center" },
                [
                  _c("div", { staticClass: "card-header" }, [
                    _c("h4", { staticClass: "card-title" }, [_vm._v("Modal")]),
                  ]),
                  _c(
                    "p-button",
                    {
                      attrs: { type: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.modals.classic = true
                        },
                      },
                    },
                    [_vm._v("\n              Classic modal\n            ")]
                  ),
                  _c(
                    "p-button",
                    {
                      attrs: { type: "info" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.modals.notice = true
                        },
                      },
                    },
                    [_vm._v("\n              Notice modal\n            ")]
                  ),
                  _c(
                    "p-button",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.modals.mini = true
                        },
                      },
                    },
                    [_vm._v("\n              Small alert modal\n            ")]
                  ),
                  _c(
                    "modal",
                    {
                      attrs: {
                        show: _vm.modals.classic,
                        headerClasses: "justify-content-center",
                      },
                      on: {
                        "update:show": function ($event) {
                          return _vm.$set(_vm.modals, "classic", $event)
                        },
                      },
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "title title-up",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_vm._v("Modal title")]
                      ),
                      _c("p", [
                        _vm._v(
                          "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live\n                the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large\n                language ocean. A small river named Duden flows by their place and supplies it with the necessary\n                regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your\n                mouth."
                        ),
                      ]),
                      _c("template", { slot: "footer" }, [
                        _c(
                          "div",
                          { staticClass: "left-side" },
                          [
                            _c(
                              "p-button",
                              {
                                attrs: { type: "default", link: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.modals.classic = false
                                  },
                                },
                              },
                              [_vm._v("Never mind")]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "divider" }),
                        _c(
                          "div",
                          { staticClass: "right-side" },
                          [
                            _c(
                              "p-button",
                              {
                                attrs: { type: "danger", link: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.modals.classic = false
                                  },
                                },
                              },
                              [_vm._v("Delete")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "modal",
                    {
                      attrs: {
                        show: _vm.modals.notice,
                        footerClasses: "justify-content-center",
                        type: "notice",
                      },
                      on: {
                        "update:show": function ($event) {
                          return _vm.$set(_vm.modals, "notice", $event)
                        },
                      },
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass: "modal-title",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_vm._v("How Do You Become an Affiliate?")]
                      ),
                      [
                        _c("div", { staticClass: "instruction" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-8" }, [
                              _c("strong", [_vm._v("1. Register")]),
                              _c("p", { staticClass: "description" }, [
                                _vm._v(
                                  "The first step is to create an account at\n                        "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "http://www.creative-tim.com/",
                                    },
                                  },
                                  [_vm._v("Creative Tim")]
                                ),
                                _vm._v(
                                  ". You can choose a social network or go for the classic version, whatever works best for you."
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("div", { staticClass: "picture" }, [
                                _c("img", {
                                  staticClass: "rounded img-raised",
                                  attrs: {
                                    src: "static/img/background/daniel-olahs.jpg",
                                    alt: "Thumbnail Image",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "instruction" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-8" }, [
                              _c("strong", [_vm._v("2. Apply")]),
                              _c("p", { staticClass: "description" }, [
                                _vm._v(
                                  "The first step is to create an account at\n                        "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "http://www.creative-tim.com/",
                                    },
                                  },
                                  [_vm._v("Creative Tim")]
                                ),
                                _vm._v(
                                  ". You can choose a social network or go for the classic version, whatever works best for you."
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("div", { staticClass: "picture" }, [
                                _c("img", {
                                  staticClass: "rounded img-raised",
                                  attrs: {
                                    src: "static/img/background/david-marcu.jpg",
                                    alt: "Thumbnail Image",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("p", [
                          _vm._v(
                            "If you have more questions, don't hesitate to contact us or send us a tweet @creativetim. We're here to help!"
                          ),
                        ]),
                      ],
                      _c(
                        "div",
                        {
                          staticClass: "justify-content-center",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "p-button",
                            {
                              attrs: { type: "info", round: "" },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.modals.notice = false
                                },
                              },
                            },
                            [_vm._v("Sounds good!")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "modal",
                    {
                      staticClass: "modal-primary",
                      attrs: {
                        show: _vm.modals.mini,
                        "show-close": false,
                        headerClasses: "justify-content-center",
                        type: "mini",
                      },
                      on: {
                        "update:show": function ($event) {
                          return _vm.$set(_vm.modals, "mini", $event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "modal-profile ml-auto mr-auto",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("i", { staticClass: "nc-icon nc-bulb-63" })]
                      ),
                      _c("p", [
                        _vm._v("Always have an access to your profile"),
                      ]),
                      _c("template", { slot: "footer" }, [
                        _c(
                          "div",
                          { staticClass: "left-side" },
                          [
                            _c(
                              "p-button",
                              {
                                attrs: { type: "default", link: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.modals.mini = false
                                  },
                                },
                              },
                              [_vm._v("Back")]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "divider" }),
                        _c(
                          "div",
                          { staticClass: "right-side" },
                          [
                            _c(
                              "p-button",
                              {
                                attrs: { type: "default", link: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.modals.mini = false
                                  },
                                },
                              },
                              [_vm._v("Close")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }