import axios from "axios";
import util from '../helpers/util';
import {router} from "../main.js";
import store from './store';
import { Service } from 'axios-middleware';

let prefix = process.env.VUE_APP_API_BASE_URL;
window.API_BASE_URL = prefix;

const client = axios.create({
  baseURL: prefix,
  timeout: 10000000,
  maxContentLength: 50 * 1024 * 1024,
});

const service = new Service(client);

service.register({
  onRequest(config) {
    config.headers.Authorization = 'Bearer ' + util.getCookie('token');
    return config;
  },
  onResponseError(error) {
    if (error && error.response) {
      let res = error.response;

      if (res.status == 401) {
        util.eraseCookie('token');
        util.eraseCookie('user');
        util.eraseCookie('role');
        store.state.token = '';
        alert("Phiên làm việc đã hết hạn, vui lòng đăng nhập lại");
        router.push({name: 'Login'});
        return;
      }

      if (res.data && typeof res.data === 'string') {
        res.data = JSON.parse(res.data);
      }

      if (res.status == 422) {
        let errors = res.data.errors;
        let message = util.parseMessageError422(errors);
        throw message;
      }

      throw res.data.message;

    }
  }
});

let api = {
  get: function(url) {
    return client.get(prefix + url, {});
  },
  post: function(url, data) {
    return client.post(prefix + url, data);
  },
  put: function(url, data) {
    return client.put(prefix + url, data);
  },
  delete: function(url) {
    return client.delete(prefix + url, {});
  }
}

export default api;
