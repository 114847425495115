import { render, staticRenderFns } from "./Content.vue?vue&type=template&id=6cf577c2&"
import script from "./Content.vue?vue&type=script&lang=js&"
export * from "./Content.vue?vue&type=script&lang=js&"
import style0 from "./Content.vue?vue&type=style&index=0&id=6cf577c2&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/truyen/workspace/dlvn/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6cf577c2')) {
      api.createRecord('6cf577c2', component.options)
    } else {
      api.reload('6cf577c2', component.options)
    }
    module.hot.accept("./Content.vue?vue&type=template&id=6cf577c2&", function () {
      api.rerender('6cf577c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dashboard/Layout/Content.vue"
export default component.exports