var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "navbar",
    {
      attrs: {
        "show-navbar": _vm.showNavbar,
        transparent: false,
        position: "fixed",
      },
    },
    [
      _c(
        "div",
        { staticClass: "navbar-wrapper" },
        [
          _c("navbar-toggle-button"),
          _c("a", { staticClass: "navbar-brand" }, [
            _vm._v(_vm._s(_vm.pageTitle)),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "navbar-menu" },
        _vm._l(_vm.actions, function (action, index) {
          return _c(
            "el-button",
            {
              key: index,
              staticClass: "m-0",
              attrs: { type: action.type, icon: "" },
              on: { click: action.callback },
            },
            [
              _c("i", { class: action.icon }),
              _vm._v(
                "\n      " + _vm._s(action.label || action.title) + "\n    "
              ),
            ]
          )
        }),
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }