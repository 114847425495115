var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card card-user" }, [
      _c("div", { staticClass: "image" }, [
        _c("img", {
          attrs: { src: "static/img/background/damir-bosnjak.jpg", alt: "..." },
        }),
      ]),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "author" }, [
          _c("a", { attrs: { href: "#" } }, [
            _c("img", {
              staticClass: "avatar border-gray",
              attrs: { src: "static/img/mike.jpg", alt: "..." },
            }),
            _c("h5", { staticClass: "title" }, [_vm._v("Chet Faker")]),
          ]),
          _c("p", { staticClass: "description" }, [
            _vm._v("\n        @chetfaker\n      "),
          ]),
        ]),
        _c("p", { staticClass: "description text-center" }, [
          _vm._v('\n      "I like the way you work it\n      '),
          _c("br"),
          _vm._v(" No diggity\n      "),
          _c("br"),
          _vm._v(' I wanna bag it up"\n    '),
        ]),
      ]),
      _c("div", { staticClass: "card-footer" }, [
        _c("hr"),
        _c("div", { staticClass: "button-container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-3 col-md-6 col-6 ml-auto" }, [
              _c("h5", [
                _vm._v("12\n            "),
                _c("br"),
                _c("small", [_vm._v("Files")]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-lg-4 col-md-6 col-6 ml-auto mr-auto" },
              [
                _c("h5", [
                  _vm._v("2GB\n            "),
                  _c("br"),
                  _c("small", [_vm._v("Used")]),
                ]),
              ]
            ),
            _c("div", { staticClass: "col-lg-3 mr-auto" }, [
              _c("h5", [
                _vm._v("24,6$\n            "),
                _c("br"),
                _c("small", [_vm._v("Spent")]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }