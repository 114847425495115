var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "navbar",
    { attrs: { "navbar-menu-classes": "navbar-right" } },
    [
      _c(
        "template",
        { slot: "navbar-menu" },
        [
          _c(
            "router-link",
            { staticClass: "nav-item", attrs: { to: "/register", tag: "li" } },
            [
              _c("a", { staticClass: "nav-link" }, [
                _c("i", { staticClass: "nc-icon nc-book-bookmark" }),
                _vm._v(" Đăng ký\n      "),
              ]),
            ]
          ),
          _c(
            "router-link",
            { staticClass: "nav-item", attrs: { to: "/login", tag: "li" } },
            [
              _c("a", { staticClass: "nav-link" }, [
                _c("i", { staticClass: "nc-icon nc-tap-01" }),
                _vm._v(" Đăng nhập\n      "),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }